import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { UploaderFile } from '../_models/uploader.file';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChunkedUploader {
  files: UploaderFile[] = [];
  constructor(private http: HttpClient) { }

  public open(
    file: File,
    index: number,
    onFileProgress: (index: number, rangeStart: number, guid: string) => void,
    onFileUploaded: (index: number, guid: string) => void,
    onError: (index: number, message: string) => void,
    onCancelled: (index: number) => void
  ) {
    let uploaderFile = new UploaderFile(file, index, 1024 * 1024); //1MB chunk
    uploaderFile.onFileProgress = onFileProgress;
    uploaderFile.onFileUploaded = onFileUploaded;
    uploaderFile.onError = onError;
    uploaderFile.onCancelled = onCancelled;

    this.files.push(uploaderFile);
    this.upload(uploaderFile, false);

    return uploaderFile;
  }

  public cancel(uploaderFile: UploaderFile) {
    uploaderFile.isCancelled = true;
  }

  public remove(uploaderFile: UploaderFile, removeFromList: boolean) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.delete<any>(`${environment.apiUrl}/api/file/cancel?guid=${uploaderFile.guid}`, { headers: headers })
      .subscribe({
        next: data => {
          if (typeof (uploaderFile.onCancelled) === 'function') {
            uploaderFile.onCancelled(uploaderFile.index, removeFromList);
          }
        },
        error: error => {
          console.log(error);
        }
      });
  }

  public resume(uploaderFile: UploaderFile) {
    this.upload(uploaderFile, uploaderFile.rangeEnd === uploaderFile.fileSize);
  }

  private upload(uploaderFile: UploaderFile, lastChunk: Boolean) {
    let chunk = uploaderFile.file?.slice(uploaderFile.rangeStart, uploaderFile.rangeEnd);
    let headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Content-Range': `bytes ${uploaderFile.rangeStart}-${uploaderFile.rangeEnd}/${uploaderFile.fileSize}`
    });
    this.http.put<any>(`${environment.apiUrl}/api/file/upload?guid=${uploaderFile.guid}&fileName=${uploaderFile.file.name}&type=${uploaderFile.file.type}`, chunk, { headers: headers })
      .subscribe({
        next: data => {
          if (data && data.statusCode == 200) {
            if (data.value) {
              uploaderFile.guid = data.value
            }

            if (uploaderFile.isCancelled) {
              this.remove(uploaderFile, true);
            }
            else {
              uploaderFile.rangeStart = uploaderFile.rangeEnd;
              uploaderFile.rangeEnd = uploaderFile.rangeStart + uploaderFile.chunkSize;
              if (uploaderFile.rangeEnd > uploaderFile.fileSize) {
                uploaderFile.rangeEnd = uploaderFile.fileSize;
              }

              uploaderFile.progress = Math.floor(uploaderFile.rangeStart / uploaderFile.fileSize * 100);
              if (typeof (uploaderFile.onFileProgress) === 'function') {
                uploaderFile.onFileProgress(uploaderFile.index, uploaderFile.rangeStart, data.value);
              }

              if (!uploaderFile.isPaused && !lastChunk) {
                this.upload(uploaderFile, uploaderFile.rangeEnd === uploaderFile.fileSize);
              }
            }

            if (lastChunk) {
              uploaderFile.progress = 100;
              if (typeof (uploaderFile.onFileUploaded) === 'function') {
                uploaderFile.onFileUploaded(uploaderFile.index, data.value);
              }
            }
          }
          else {
            if (typeof (uploaderFile.onError) === 'function') {
              uploaderFile.onError(uploaderFile.index, data);
            }
            this.remove(uploaderFile, false);
          }
        },
        error: error => {
          if (typeof (uploaderFile.onError) === 'function') {
            uploaderFile.onError(uploaderFile.index, error);
          }
          this.remove(uploaderFile, false);
        }
      });
  }
}
