import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { DialogService } from "@/_services/dialog.service";
import { JsonCollectionResponse } from "@/_models/response";
import { environment } from "@envs/environment";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { VariableDetailsDialogComponent } from "@/_components/variable-details-dialog";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'report-create',
  styleUrls: ['report-create.component.scss'],
  templateUrl: 'report-create.component.html',
})
export class ReportCreateComponent {
  public reportForm: FormGroup;
  public config: any;
  public filter: any;
  public reportName: any;
  public fakeData: any = [{

  }];
  public previewGridConfig: any = {
    _self: this,
    colDefs: []
  };

  http: HttpClient;

  public selectedDimension: any;
  public selectedColumns: any = [];
  public showHint: boolean = true;
  public hintText: string | undefined;

  public dimensions = [
    {
      type: "asset",
      label: "Asset IDs",
      id: "assetId"
    },
    {
      label: "Events - Vegetation",
      id: "event_1",
      type: "event"
    }
  ]

  public dataLayers = [
    {
      name: "Asset attributes",
      visible: true,
      id: 'assetAttrs',
      hint: "Asset attributes are the static asset information uploaded in the system. Please select those you want to display in your report.",
      columns: [
        {
          name: "Asset Name",
          field: "assetName",
          selected: false,
          visible: true,
          active: false
        },
        {
          name: "Asset Type",
          field: "assetType",
          selected: false,
          visible: true,
          active: false
        },
        {
          name: "Asset Category",
          field: "assetCategory",
          selected: false,
          visible: true,
          active: false
        },
        {
          name: "Asset Location",
          field: "assetLocation",
          selected: false,
          visible: true,
          active: false
        },
        {
          name: "Location Subgroup",
          field: "assetLocationSubgroup",
          selected: false,
          visible: true,
          active: false
        }
      ]
    },
    {
      name: "Data layer 1",
      id: 'dataLayer1',
      visible: true,
      columns: [
        {
          name: "Class",
          field: "incidentClass",
          hint: "test 2",
          selected: false,
          visible: true,
          active: false
        }
      ]
    },
    {
      name: "Data layer 2",
      id: 'dataLayer2',
      visible: true,
      columns: [
        {
          name: "Class",
          field: "incidentClass",
          selected: false,
          visible: true,
          active: false
        }
      ]
    },
    {
      name: "Data layer 3",
      id: 'dataLayer3',
      visible: true,
      columns: [
        {
          name: "Minimum distance from asset",
          field: "minimumDistance",
          selected: false,
          visible: true,
          active: false
        },
        {
          name: "Total length of asset impacted",
          field: "totalLenghtImpacted",
          selected: false,
          visible: true,
          active: false
        }
      ]
    }
  ]

  constructor(
    private _http: HttpClient,
    public dialogService: DialogService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {
    this.http = _http;
    this.showHint = false;
    this.reportForm = this.fb.group({
      columns: [null, null],
    });
    this.reportForm.controls["columns"].disable();
  }
  onDimensionChange($event: any) {
    let index = this.previewGridConfig.colDefs.findIndex((x: any) => { return x.field == "id"})
    if(index > -1) {
      this.previewGridConfig.colDefs.splice(index, 1);
    }
    if($event.type=="asset") {
      this.previewGridConfig.colDefs.unshift({
        title: "Asset ID",
        field: "id",
      })
    }
    if($event.type=="event") {
      this.previewGridConfig.colDefs.unshift({
        title: "Event ID",
        field: "id",
      })
    }
    this.reportForm.controls["columns"].enable();
  }
  onDimensionClear($event: any) {
    this.reportForm.controls["columns"].disable();
  }

  selectColumn(column: any, parentId: string) {
    column.selected = !column.selected;
    if(!this.selectedColumns.find((x: any) => { return x.name == column.name && x.parentId == parentId})) {
      column.parentId = parentId
      this.selectedColumns.push(column)
    }
    if(!this.previewGridConfig.colDefs.find((x: any) => { return x.id == column.name + column.parentId})) {
      this.previewGridConfig.colDefs.push({
        id:  column.name + column.parentId,
        title: column.name,
        field: column.field
      })
    }
  }
  removeColumn(column: any) {
    let index = this.selectedColumns.findIndex((x: any) => { return x.name == column.name && x.parentId == column.parentId})
    column.selected = false;
    if(index > -1) {
      this.selectedColumns.splice(index, 1);
    }
    index = this.previewGridConfig.colDefs.findIndex((x: any) => { return x.id == column.name + column.parentId})
    if(index > -1) {
      this.previewGridConfig.colDefs.splice(index, 1);
    }
  }
  dropColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.previewGridConfig.colDefs, event.previousIndex+1, event.currentIndex+1);
  }

  onColumnSelectClose() {
    this.showHint = false;
    this.deactivateAllColumns()
  }

  deactivateAllColumns() {
    this.dataLayers.forEach((dataLayer) => {
      dataLayer.columns.forEach((column) => {
        column.active = false;
      });
    })
  }

  onMouseEnterColumnOption(column: any) {
    this.deactivateAllColumns()
    if(column.hint) {
      this.hintText = column.hint;
      this.showHint = true;
      column.active = true;
    }
    else {
      this.showHint = false;
    }
  }
  onMouseEnterColumnGroup(columnGroup: any) {
    this.deactivateAllColumns()
    if(columnGroup.hint) {
      this.hintText = columnGroup.hint;
      this.showHint = true;
    }
    else {
      this.showHint = false;
    }
  }

  customSearchFn(term: string, item: {
    label: string,
    id: string
  }) {
    term = term.toLowerCase();
    return item.label.toLowerCase().indexOf(term) > -1;
  }
  customColumnsSearchFn(term: string, item: {
    name: string,
    field: string
  }) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1;
  }

  onColumnSearch($event: any) {
    let searchTerm = $event.term.toLowerCase();
    this.dataLayers.forEach((dataLayer) => {
      dataLayer.visible = dataLayer.name.toLowerCase().indexOf(searchTerm) > -1;
      dataLayer.columns.forEach((column) => {
        if(column.name.toLowerCase().indexOf(searchTerm) > -1 && !column.selected) {
          column.visible = true;
          dataLayer.visible = true;
        }
        else {
          column.visible = false;
        }
    });
  })}

  create() {}

  close() {
    this.router.navigate(["/report/list"]);
  }
  openDetails(item: any) {
    this.dialog.open(VariableDetailsDialogComponent, {
      data: item
    });
  }
  onNoClick(): void {
    //this.dialogRef.close();
  }
}
