<div class="sidenav">
  <div class="login-main-text">
    <h2><img src="assets/spottitt-logo.png" alt="Spottitt"/></h2>
  </div>
</div>
<div class="main">
  <div class="col-md-6 col-sm-12 login-wrapper flex-column justify-content-evenly d-flex h-100">
    <div class="login-form ">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="!sent">
        <div class="form-group mb-3">
          <label for="username">Username / E-mail</label>
          <input type="text" id="username" formControlName="username" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f['username'].errors }"/>
          <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
            <div *ngIf="f['username'].errors['required']">Username is required</div>
          </div>
        </div>
        <div class="form-group">
          <div *ngIf="error" (click)="error=''" class="invalid-feedback d-flex mb-2">
            {{error}}
          </div>
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            <i-feather *ngIf="!loading" name="key" class="sm"></i-feather>
            Reset password
          </button>
          <button type="button" routerLink="/login" class="btn btn-link">Back</button>
        </div>
      </form>

      <div class="form-group" *ngIf="sent">
        <h3>We have sent You link to reset Your password</h3>
        <button type="button" routerLink="/login" class="btn btn-link">Back</button>
      </div>
    </div>
  </div>
</div>
