<h1 mat-dialog-title>Create Variable</h1>
<mat-dialog-content>
  <div class="row mb-2">
    <mat-form-field appearance="outline" style="margin-right: 10px; width: 100%; margin-top: 10px;">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="entity.name" required>
    </mat-form-field>
  </div>
  <div class="row mb-2">
    <mat-form-field appearance="outline" style="margin-right: 10px; width: 100%;">
      <mat-label>Description</mat-label>
      <textarea matInput [(ngModel)]="entity.description" required></textarea>
    </mat-form-field>
  </div>
  <div class="row mb-4">
    <label class="form-label form-label m-1">Select parent variable:</label>
    <div class="d-flex flex-row col-auto m-1">
      <app-lookup large [name]="'INCIDENTTYPE'" [(ngModel)]="entity.types" mode="multi"></app-lookup>
    </div>
  </div>
  <div class="row mb-4" *ngIf="entity.selectedFilters.length > 0">
    <label class="form-label form-label m-1">Select your filters:</label>
    <ng-container *ngFor="let filter2 of entity.selectedFilters">
      <div class="d-flex flex-row col-auto m-1" *ngIf="filter2.type != 'group'">
        <label for="form-{{filter2.field}}" class="form-label form-label-sm m-1">{{filter2.title}}</label>
        <app-filter-input *ngIf="filter2.type == 'string'" [(ngModel)]="filter2.value" type="text"></app-filter-input>
        <app-filter-input *ngIf="filter2.type == 'number'" [(ngModel)]="filter2.value" type="number"></app-filter-input>
        <app-filter-input *ngIf="filter2.type == 'numberlimited'" [(ngModel)]="filter2.value" type="numberlimited"></app-filter-input>
        <app-filter-input *ngIf="filter2.type == 'date'" [(ngModel)]="filter2.value" type="date"></app-filter-input>
        <app-lookup *ngIf="filter2.type == 'lookup-single'" [name]="filter2.lookupName" [(ngModel)]="filter2.value" mode="single"></app-lookup>
        <app-lookup *ngIf="filter2.type == 'lookup-multi'" [name]="filter2.lookupName" [(ngModel)]="filter2.value" mode="multi"></app-lookup>
        <app-lookup *ngIf="filter2.type == 'lookup-single-nonnull'" [name]="filter2.lookupName" [(ngModel)]="filter2.value" mode="singlenn"></app-lookup>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <button (click)="close()" class="btn btn-secondary" style="margin-right:16px;" mat-dialog-close>Close</button>
  <button (click)="create()" class="btn btn-primary">Save</button>
</div>
