<h1 mat-dialog-title>{{data.name}}</h1>
<div mat-dialog-content>
  {{data.description}}
  <table class="table table-sm table-striped mb-0 table-variable-params">
    <tbody>
    <tr *ngFor="let filter of data.selectedFilters">
      <td class="text-end">{{filter.title}}</td>
      <td>
        <ng-container *ngIf="filter.type != 'lookup-type'">
          <span [hidden]="filter.value.operator != 1">=</span>
          <span [hidden]="filter.value.operator != 2">≠</span>
          <span [hidden]="filter.value.operator != 3">&gt;</span>
          <span [hidden]="filter.value.operator != 4">&ge;</span>
          <span [hidden]="filter.value.operator != 5">&lt;</span>
          <span [hidden]="filter.value.operator != 6">&le;</span>
          <span [hidden]="filter.value.operator != 7"></span>
          <span [hidden]="filter.value.operator != 8">not </span>
          <span [hidden]="filter.value.operator != 9"></span>
          <span [hidden]="filter.value.operator != 11">&isin; (</span>
          <span [hidden]="filter.value.operator != 12">&notin; (</span>
          {{filter.value.valuePrimary}}
          <span *ngIf="filter.value.operator > 10">- {{filter.value.valueSecondary}}</span>
          <span *ngIf="filter.value.operator > 10">)</span>
        </ng-container>
        <span *ngIf="filter.type == 'lookup-type'"><app-lookup-as-text name="INCIDENTTYPE" [showValues]="filter.value.valuePrimary" /></span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close class="btn btn-secondary">Close</button>
</div>
