<app-filter-panel [config]="config?.filters" *ngIf="config?.filters?.length" (filterEvent)="filter = $event"></app-filter-panel>
<table class="table table-sm">
  <thead>
    <tr>
      <th *ngIf="config?.selectable" class="align-middle" style="width: 32px"><input *ngIf="!loading" type="checkbox" [(ngModel)]="allSelected" (change)="selectAllChange()" [indeterminate]="selectedItemsList.length > 0 && selectedItemsList.length < data.length" /></th>
      <ng-container *ngFor="let column of config?.colDefs">
        <th *ngIf="column.visible" (click)="sortColumn(column.sortColumn)" class="align-middle" [ngClass]="{'sortable': column.sortColumn != undefined , 'thin': (column.type == 'actionpossible' || column.type == 'action' )}">
          <span [innerHTML]="column.title"></span>
          <i-feather *ngIf="column.sortColumn != undefined && column.sortColumn == sort?.columnName && sort?.order == 'desc'" name="chevron-up" class="sm"></i-feather>
          <i-feather *ngIf="column.sortColumn != undefined && column.sortColumn == sort?.columnName && sort?.order == 'asc'" name="chevron-down" class="sm"></i-feather>
        </th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let i of [].constructor(loading ? count : 0)">
      <td *ngIf="config?.selectable"><ngx-skeleton-loader count="1" [theme]="{margin: 0, display: 'block', height: '27px', width: '32px'}"></ngx-skeleton-loader></td>
      <td class="align-middle" *ngFor="let i of [].constructor(lodashFilter(config?.colDefs, { 'visible': true }).length)"><ngx-skeleton-loader count="1" [theme]="{margin: 0, display: 'block', height: '27px'}"></ngx-skeleton-loader></td>
    </tr>
    <ng-container *ngIf="!loading">
      <tr *ngFor="let item of data">
        <td *ngIf="config?.selectable"><input type="checkbox" [(ngModel)]="item.selected" (change)="changeSelection()"></td>
        <ng-container *ngFor="let column of config?.colDefs">
          <td *ngIf="column.visible" class="align-middle">
            <span *ngIf="column?.type == undefined">{{ item[column.field] }}</span>
            <div *ngIf="column?.type == 'action'">
              <button class="btn-sm btn btn-primary" (click)="runAction(column, item)" [disabled]="item.loader" [title]="column.btnTooltip">
                <span *ngIf="item.loader" class="spinner-border spinner-border-sm mr-1"></span>
                <i-feather *ngIf="!item.loader && column.btnIconName" [name]="column.btnIconName" class="xsm"></i-feather>
                {{column.btnText}}
              </button>
            </div>
          </td>
        </ng-container>
      </tr>
      <tr>
        <td [attr.colspan]="config?.colDefs?.length" *ngIf="dataNotFound">No data found</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot *ngIf="pageNumber > 1">
    <tr>
      <td [attr.colspan]="config?.colDefs?.length">
        <nav aria-label="Page navigation example">
          <ul class="pagination pagination-sm mb-0">
            <li class="page-item" [ngClass]="{'disabled': (page < 1)}">
              <a class="page-link" aria-label="Previous" (click)="prevPage()">
                <i-feather name="chevron-left" class="sm"></i-feather>
              </a>
            </li>
            <li class="page-item" *ngIf="pageNumber > 5 && page > 4"><a class="page-link" (click)="goToPage(0)">1</a></li>
            <li class="page-item disabled" *ngIf="pageNumber > 6 && page > 4"><a class="page-link" href="#">..</a></li>
            <li class="page-item" [ngClass]="{'active': (i + rangeOffset) == 0}" *ngFor="let item of [].constructor(range); let i = index">
              <a class="page-link" (click)="goToPage(page + (i + rangeOffset))">{{page + (i + rangeOffset + 1)}}</a>
            </li>
            <li class="page-item disabled" *ngIf="pageNumber > 6 && (pageNumber - page) > 4"><a class="page-link" href="#">..</a></li>
            <li class="page-item" *ngIf="pageNumber > 5 && (pageNumber - page) > 3"><a class="page-link" (click)="goToPage(pageNumber-1)">{{pageNumber}}</a></li>
            <li class="page-item" [ngClass]="{'disabled': !((page + 1) < pageNumber)}">
              <a class="page-link" aria-label="Next" (click)="nextPage()">
                <i-feather name="chevron-right" class="sm"></i-feather>
              </a>
            </li>
          </ul>
        </nav>
      </td>
    </tr>
  </tfoot>
</table>
