import { GridComponent } from "@/_components/grid";
import { ResourcesService } from "@/_services/resources.service";
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { environment } from "@envs/environment";
import { ConfirmDialogComponent } from "@/_components/confirm-dialog";
import { CreateVariableComponent } from "@/_components/create-variable";
import { JsonResponse } from "@/_models/response";
import { VariablesService } from "@/_services/variables.service";
import {VariableDetailsDialogComponent} from "@/_components/variable-details-dialog";

@Component({
  selector: 'app-variables',
  providers: [
    {
      provide: ResourcesService,
      useClass: VariablesService
    }
  ],
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss']
})
export class VariablesComponent implements OnInit, AfterViewInit {
  public items: any;
  http: HttpClient;
  public gridConfig: any;
  public filter: any;
  @ViewChild('dataGrid') dataGrid!: GridComponent;
  constructor(
    private _http: HttpClient,
    private dialog: MatDialog,
    public _resourceService: ResourcesService,
    private router: Router
  ) {
    this.http = _http;
    this.refreshGrid();
    this.gridConfig = {
      _self: this,
      colDefs: [
        {
          title: "Name",
          field: "name",
          sortColumn: "name",
        },
        {
          title: "Description",
          field: "description",
          sortColumn: "description",
        },
        {
          title: '',
          type: 'action',
          btnText: 'Details',
          btnTooltip: 'Show more details',
          action: this.openDetails,
          paramFields: ['additionalColumns']
        },
        {
          title: '',
          type: 'action',
          btnText: '',
          btnIconName: 'trash',
          btnTooltip: 'Delete',
          action: this.remove,
          paramFields: ['id']
        }
      ],
      filters: [
        {
          field: "name",
          title: "Name",
          type: "string"
        },
        {
          field: "description",
          title: "Description",
          type: "string"
        }
      ]
    }
  }
  refreshGrid() {
    if (this.dataGrid != undefined) {
      this.dataGrid.refreshGrid();
    }
  }

  ngOnInit(): void { }
  ngAfterViewInit(): void { }

  remove(item?: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        icon: "alert-triangle",
        message: "Are you sure to delete \"" + item.name + "\" variable?",
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.delete<JsonResponse>(`${environment.apiUrl}/api/Variable/Delete?id=${item.id}`).subscribe(result => {
          if (result) {
            if (this.dataGrid != undefined) {
              this.dataGrid.refreshGrid();
            }
          }
        });
      }
  });

  }
  openDetails(item: any) {
    this.dialog.open(VariableDetailsDialogComponent, {
      data: item
    });
  }

  createVariable() {
    const dialogRef = this.dialog.open(CreateVariableComponent, {
      data: {},
      width: '60vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshGrid();
    });
  }
}
