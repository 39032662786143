export class UploaderFile {
  guid: string | undefined;
  file: File;
  index: number;
  fileSize: number;
  chunkSize: number;
  rangeStart: number;
  rangeEnd: number;
  progress: number;
  isCancelled: boolean;
  isPaused: boolean;
  onFileUploaded: ((index: number, guid: string) => void) | undefined;
  onFileProgress: ((index: number, rangeStart: number, guid: string) => void) | undefined;
  onError: ((index: number, message: string) => void) | undefined;
  onCancelled: ((index: number, removeFromList: boolean) => void) | undefined;

  constructor(file: File, index: number, chunkSize: number) {
    this.file = file;
    this.index = index;
    this.fileSize = file.size;
    this.chunkSize = chunkSize;
    this.progress = 0;
    this.rangeStart = 0;
    this.guid = "";
    this.isCancelled = false;
    this.isPaused = false;
    if (this.chunkSize > this.fileSize) {
      this.rangeEnd = this.fileSize;
    }
    else {
      this.rangeEnd = this.chunkSize;
    }
  }
}
