export const defaultEventsGridConfigColDef = [
    {
      title: '',
      type: 'action',
      btnText: '',
      btnIconName: 'map',
      btnTooltip: 'Show on map',
      action: 'showOnMap',
      paramFields: ['id']
    },
    {
      title: "AssetName",
      field: "assetName",
      sortColumn: "Asset.Name",
    },
    {
      title: "Type",
      field: "class",
      sortColumn: "IncidentClassLabel",
    },
    {
      title: "Horizontal distance [m]",
      field: "distance",
      sortColumn: "AssetDistance",
    },
   {
      title: "Absolute distance [m]",
      field: "distance3D",
      sortColumn: "AssetDistance3D",
    },
    {
      title: "Ndvi",
      field: "ndvi",
      sortColumn: "NDVI",
    },
    {
      title: "Height estimate [m]",
      field: "height",
      sortColumn: "Height",
    },
    {
      title: "Area [m2]",
      field: "area",
      sortColumn: "Area",
    },
    {
      title: "Date",
      field: "date",
      sortColumn: "Date",
    }
  ];
