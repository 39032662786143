import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import {OlMapEvent} from "@/_models/ol-map-event";

@Injectable({
  providedIn: 'root'
})
export class OlMapService {
  private _event: BehaviorSubject<OlMapEvent> = new BehaviorSubject(new OlMapEvent());
  public readonly event: Observable<OlMapEvent> = this._event.asObservable();

  public addEvent(target: string, action: string, data: any) {
    let event = new OlMapEvent();
    event.target = target;
    event.action = action;
    event.data = data
    this._event.next(event);
  }
}
