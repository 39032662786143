import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent {
  public _config: any;
  @Input() set config(value: any)  {
    if(value !== undefined && Object.keys(value).length > 0) {
      this._config = value;
      this.readFilter()
    }
  }
  @Input() showHideBtn: boolean = false;
  @Output() filterEvent = new EventEmitter<any>();
  public filter: any;
  public numberOfGroups: number | undefined;
  public selectedGroup: any;

  constructor() {}

  selectFilterGroup(group: any) {
    this.clearFilterGroup(this.selectedGroup);
    this.selectedGroup = group.value;
  }

  applyFilter() {
    this.readFilter()
  }

  clearFilter() {
    this.filter = {};
    if(this._config != undefined) {
      for(var filter of this._config) {
        if(filter?.value != undefined && filter.value != '') {
          filter.value = undefined;
        }
        if(filter.type == 'group') {
          for(var subfilter of filter?.filters) {
            if(subfilter?.value != undefined && subfilter.value != '') {
              subfilter.value = undefined;
            }
          }
        }
      }
    }
    this.filterEvent.emit(this.filter);
  }

  clearFilterGroup(group: string) {
    if(this._config != undefined) {
      for(var filter of this._config) {
        if(filter.type == 'group' && filter.field == group) {
          for(var subfilter of filter?.filters) {
            if(subfilter?.value != undefined && subfilter.value != '') {
              subfilter.value = undefined;
            }
          }
        }
      }
    }
    this.filterEvent.emit(this.filter);
  }

  readFilter(): void {
    if (this._config !== undefined) {
      this.filter = {};
      for (var filter of this._config) {
        if (filter?.value != undefined && filter.value != '') {
          this.filter[filter.field] = filter.value;
        }
        if (filter.type == 'group') {
          for (var subfilter of filter?.filters) {
            if (subfilter?.value != undefined && subfilter.value != '') {
              this.filter[subfilter.field] = subfilter.value;
            }
          }
        }
      }
      this.filterEvent.emit(this.filter);
    }
  }

}
