import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'variable-details-dialog',
  styleUrls: ['variable-details-dialog.component.scss'],
  templateUrl: 'variable-details-dialog.component.html',
})
export class VariableDetailsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<VariableDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
