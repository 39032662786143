<h1 mat-dialog-title>Report parameters used for generating</h1>
<div mat-dialog-content>
  <table class="table table-sm table-bordered w-100">
    <tbody>
      <tr *ngIf="variable">
        <td>Selected varaible</td>
        <td>
          <h6><strong>{{variable.name}}</strong></h6>
          {{variable.description}}
        </td>
      </tr>
      <tr *ngIf="variable">
        <td>Variable parameters</td>
        <td>
          <table class="table table-sm table-striped mb-0 table-variable-params">
            <tbody>
            <tr *ngFor="let filter of variable.selectedFilters">
              <td class="text-end">{{filter.title}}</td>
              <td>
                <ng-container *ngIf="filter.type != 'lookup-type'">
                  <app-filter-as-text [value]="filter.value"></app-filter-as-text>
                </ng-container>
                <span *ngIf="filter.type == 'lookup-type'"><app-lookup-as-text name="INCIDENTTYPE" [showValues]="filter.value.valuePrimary" /></span>
              </td>
            </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr *ngIf="entity.parameters?.date1?.valuePrimary">
        <td *ngIf="entity.reportMode != 2">Date</td>
        <td *ngIf="entity.reportMode == 2"> Date range for pass 1</td>
        <td>
          <app-filter-as-date [value]="entity.parameters.date1"></app-filter-as-date>
        </td>
      </tr>
      <tr *ngIf="entity.parameters?.date2?.valuePrimary">
        <td>Date range for pass 2</td>
        <td>
          <app-filter-as-date [value]="entity.parameters.date2"></app-filter-as-date>
        </td>
      </tr>
      <tr *ngIf="entity.parameters != null && entity.parameters.assetFilter != null">
        <td>Asset filters</td>
        <td>
          <table class="table table-sm table-striped mb-0">
            <tbody>
            <ng-container *ngFor="let filter of assetFilter">
              <tr *ngIf="filter.value.valuePrimary != null">
                <td class="text-end">{{filter.title}}</td>
                <td>
                  <app-filter-as-text [value]="filter.value"></app-filter-as-text>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </td>
      </tr>
      <tr *ngIf="entity.parameters != null && entity.parameters.mergeDistance > 0">
        <td>Events merge radius <br/><small></small></td>
        <td>{{entity.parameters.mergeDistance}} m</td>
      </tr>
    </tbody>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close class="btn btn-secondary">Close</button>
</div>
