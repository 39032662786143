<div *ngIf="_config != undefined && _config?.length">
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedGroup" (change)="selectFilterGroup($event)" *ngIf="numberOfGroups != undefined && numberOfGroups > 1" hideSingleSelectionIndicator="true">
    <ng-container *ngFor="let filter of _config">
      <mat-button-toggle *ngIf="filter.type == 'group'" [value]="filter.field">{{filter.title}}</mat-button-toggle>
    </ng-container>
  </mat-button-toggle-group>
  <div class="collapse show multi-collapse mt-1 mb-1" *ngIf="showHideBtn">
    <button class="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" data-bs-delay='{"show":200,"hide":150}' aria-expanded="false" aria-controls="multi-collapse">
      <i-feather name="filter" class="xsm"></i-feather>
      Show filters
    </button>
  </div>
  <div class="row filter-row collapse multi-collapse" id="collapseFilter" [ngClass]="{'show': !showHideBtn}">
    <div class="d-flex flex-row col-auto mt-1 mb-1" *ngIf="showHideBtn">
      <button class="btn btn-outline-secondary btn-sm" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multi-collapse">
        <i-feather name="filter" class="xsm"></i-feather>
        Hide filters
      </button>
    </div>
    <ng-container *ngFor="let filter of _config">
      <div class="d-flex flex-row col-auto mt-1 mb-1 me-1" *ngIf="filter.type != 'group'">
        <label for="form-{{filter.field}}" class="form-label form-label-sm mt-1 mb-1 me-1" [innerHTML]="filter.title"></label>
        <app-filter-input *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="text"></app-filter-input>
        <app-filter-input *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="number"></app-filter-input>
        <app-filter-input *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="date"></app-filter-input>
        <app-lookup *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
        <app-lookup *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
        <app-lookup *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn" [disabled]="filter.disabled"></app-lookup>
      </div>
      <ng-container *ngIf="filter.type == 'group' && selectedGroup == filter.field">
        <ng-container *ngFor="let subfilter of filter?.filters">
          <div class="d-flex flex-row col-auto mt-1 mb-1 me-1">
            <label for="form-{{subfilter.field}}" class="form-label form-label-sm mt-1 mb-1 me-1" [innerHTML]="subfilter.title"></label>
            <app-filter-input *ngIf="subfilter.type == 'string'" [disabled]="filter.disabled" [(ngModel)]="subfilter.value" type="text"></app-filter-input>
            <app-filter-input *ngIf="subfilter.type == 'number'" [disabled]="filter.disabled" [(ngModel)]="subfilter.value" type="number"></app-filter-input>
            <app-filter-input *ngIf="subfilter.type == 'date'" [disabled]="filter.disabled" [(ngModel)]="subfilter.value" type="date"></app-filter-input>
            <app-lookup *ngIf="subfilter.type == 'lookup-single'" [name]="subfilter.lookupName" [(ngModel)]="subfilter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
            <app-lookup *ngIf="subfilter.type == 'lookup-multi'" [name]="subfilter.lookupName" [(ngModel)]="subfilter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="d-flex flex-row col-auto mt-1 mb-1">
      <div class="input-group input-group-sm">
        <button class="btn-sm btn btn-primary" (click)="applyFilter()">Apply</button>
        <button class="btn-sm btn btn-warning" (click)="clearFilter()">Clear</button>
      </div>
    </div>
  </div>
</div>
