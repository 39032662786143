export class Category {
  name: string;
  expression: string;
  color: string;
  outlineColor: string;
  outlineWidth: number;
  pxlval: number;
  geometryType: string;

  constructor(name: string | null, pxlval: number, geometryType?: string | null) {
    this.name = name ?? "";
    this.color = "#000000ff";
    this.outlineColor = "#000000ff";
    this.outlineWidth = 2;
    this.pxlval = pxlval;
    this.expression = pxlval.toString();
    this.geometryType = geometryType ?? "";
  }
}

export class Template {
  id: number = 0;
  name: string;
  pxlvalColumn: string | null;
  nameColumn: string | null;
  labelColumn: string | null;
  categories: Category[];
  properties: Properties;

  constructor(name: string) {
    this.name = name;
    this.pxlvalColumn = null;
    this.nameColumn = null;
    this.labelColumn = null;
    this.categories = [];
    this.properties = new Properties();
  }
}

export class Properties {
  textColor: string;
  textOutlineColor: string;
  textScale: number;
  textOverflow: boolean;

  constructor() {
    this.textColor = "#000000ff";
    this.textOutlineColor = "#ffffffff";
    this.textScale = 10;
    this.textOverflow = false;
  }
}


