<span [hidden]="value.operator != 1"></span>
<span [hidden]="value.operator != 2">≠</span>
<span [hidden]="value.operator != 3">&gt;</span>
<span [hidden]="value.operator != 4">&ge;</span>
<span [hidden]="value.operator != 5">&lt;</span>
<span [hidden]="value.operator != 6">&le;</span>
<span [hidden]="value.operator != 7"></span>
<span [hidden]="value.operator != 8">not </span>
<span [hidden]="value.operator != 9"></span>
<span [hidden]="value.operator != 11">&isin; (</span>
<span [hidden]="value.operator != 12">&notin; (</span>
{{value.valuePrimary | date:"yyyy-MM-dd"}}
<span *ngIf="value.operator > 10">- {{value.valueSecondary | date:"yyyy-MM-dd"}}</span>
<span *ngIf="value.operator > 10">)</span>
