import { HttpClient } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { DialogService } from "@/_services/dialog.service";
import { JsonCollectionResponse } from "@/_models/response";
import { environment } from "@envs/environment";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { VariableDetailsDialogComponent } from "@/_components/variable-details-dialog";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ReportsLookupsService } from "../../_services/reports-lookups.service";

@Component({
  selector: 'report-create',
  providers: [
    {
      provide: ReportsLookupsService
    }
  ],
  styleUrls: ['report-create.component.scss'],
  templateUrl: 'report-create.component.html',
})
export class ReportCreateComponent {
  public reportForm: FormGroup;
  public config: any;
  public filter: any;
  public reportName: any;
  public fakeData: any = [{
  }];
  public previewGridConfig: any = {
    _self: this,
    colDefs: []
  };

  public createLoader: boolean = false;

  http: HttpClient;

  public selectedDimension: any;
  public selectedColumns: any = [];
  public showHint: boolean = true;
  public hintText: string | undefined;
  private readonly _reportsLookupsService: ReportsLookupsService;

  public dimensions = [
    {
      type: "asset",
      label: "Asset IDs",
      id: "assetId"
    },
    {
      label: "Events - Vegetation",
      id: "event_1",
      type: "event"
    }
  ]

  public dataLayers = [
    {
      name: "Asset attributes",
      visible: true,
      id: 'assetAttrs',
      hint: "Asset attributes are the static asset information uploaded in the system. Please select those you want to display in your report.",
      colDefs: [
        {
          title: "Asset Name",
          field: "assetName",
          selected: false,
          visible: true,
          active: false
        }]
    }];

  constructor(
    private _http: HttpClient,
    public dialogService: DialogService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    _reportsLookupsService: ReportsLookupsService,

  ) {
    this.http = _http;
    this.showHint = false;
    this.reportForm = this.fb.group({
      columns: [null, null],
    });
    this.reportForm.controls["columns"].disable();
    this._reportsLookupsService = _reportsLookupsService;
  }

  ngOnInit(): void {
    this._reportsLookupsService.getList("").subscribe(result => {
      if (result.ok) {
        this.dataLayers = result.items;
      }
    });
  }

  onDimensionChange($event: any) {
    let index = this.previewGridConfig.colDefs.findIndex((x: any) => { return x.field == "id" })
    if (index > -1) {
      this.previewGridConfig.colDefs.splice(index, 1);
    }
    if ($event.type == "asset") {
      this.previewGridConfig.colDefs.unshift({
        title: "Asset ID",
        field: "id",
      })
    }
    if ($event.type == "event") {
      this.previewGridConfig.colDefs.unshift({
        title: "Event ID",
        field: "id",
      })
    }
    this.reportForm.controls["columns"].enable();
  }
  onDimensionClear($event: any) {
    this.reportForm.controls["columns"].disable();
  }

  selectColumn(column: any, parentId: string, parentName: string) {
    column.selected = !column.selected;
    if (!this.selectedColumns.find((x: any) => { return x.title == column.title && x.parentId == parentId })) {
      column.parentId = parentId
      column.parentName = parentName;
      this.selectedColumns.push(column)
    }
    if (!this.previewGridConfig.colDefs.find((x: any) => { return x.id == column.title + column.parentId })) {
      this.previewGridConfig.colDefs.push({
        id: column.field + column.parentId,
        title: column.title,
        field: column.field
      })
    }
  }
  removeColumn(column: any) {
    let index = this.selectedColumns.findIndex((x: any) => { return x.title == column.title && x.parentId == column.parentId })
    column.selected = false;
    if (index > -1) {
      this.selectedColumns.splice(index, 1);
    }
    index = this.previewGridConfig.colDefs.findIndex((x: any) => { return x.id == column.title + column.parentId })
    if (index > -1) {
      this.previewGridConfig.colDefs.splice(index, 1);
    }
  }
  dropColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
    moveItemInArray(this.previewGridConfig.colDefs, event.previousIndex + 1, event.currentIndex + 1);
  }

  onColumnSelectClose() {
    this.showHint = false;
    this.deactivateAllColumns()
  }

  deactivateAllColumns() {
    this.dataLayers.forEach((dataLayer) => {
      dataLayer.colDefs.forEach((column) => {
        column.active = false;
      });
    })
  }

  onMouseEnterColumnOption(column: any) {
    this.deactivateAllColumns()
    if (column.hint) {
      this.hintText = column.hint;
      this.showHint = true;
      column.active = true;
    }
    else {
      this.showHint = false;
    }
  }
  onMouseEnterColumnGroup(columnGroup: any) {
    this.deactivateAllColumns()
    if (columnGroup.hint) {
      this.hintText = columnGroup.hint;
      this.showHint = true;
    }
    else {
      this.showHint = false;
    }
  }

  customSearchFn(term: string, item: {
    label: string,
    id: string
  }) {
    term = term.toLowerCase();
    return item.label.toLowerCase().indexOf(term) > -1;
  }
  customColumnsSearchFn(term: string, item: {
    title: string,
    field: string
  }) {
    term = term.toLowerCase();
    return item.title.toLowerCase().indexOf(term) > -1;
  }

  onColumnSearch($event: any) {
    let searchTerm = $event.term.toLowerCase();
    this.dataLayers.forEach((dataLayer) => {
      dataLayer.visible = dataLayer.name.toLowerCase().indexOf(searchTerm) > -1;
      dataLayer.colDefs.forEach((column) => {
        if (column.title.toLowerCase().indexOf(searchTerm) > -1 && !column.selected) {
          column.visible = true;
          dataLayer.visible = true;
        }
        else {
          column.visible = false;
        }
      });
    })
  }

  create() {
    var selectedDataLayers: DataLayerWithColumns[] = [];

    this.selectedColumns.forEach((column: any) => {
      let index = selectedDataLayers.findIndex((x: any) => { return x.Id == column.parentId })
      if (index > -1) {
        selectedDataLayers[index].DataLayerColumns.push({
          Name: column.field,
          DisplayName: column.title,
          Type: "",
          Alias: "",
          Hint: ""
        })
      } else {
        selectedDataLayers.push({
          Id: column.parentId,
          Name: "",
          Schema: "",
          JoinType: "",
          DataLayerColumns: [{
            Name: column.field,
            DisplayName: column.title,
            Type: "",
            Alias: "",
            Hint: ""
          }]
        })
      }
    })

    var params = {
      Name: this.reportName,
      SelectedDataLayers: selectedDataLayers
    };

    var closeme = true;

    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/Report/SaveAsReportView/`, params).subscribe(result => {
        if (result.ok) {
          this.createLoader = false;
          this.router.navigate(["/reports"]);
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
          closeme = false;
          this.createLoader = false;
        }
      });
      setTimeout(() => {
        if (closeme) {
          this.createLoader = false;
          this.router.navigate(["/reports"]);
        }
      }, 5000);
    }
  }

  close() {
    this.router.navigate(["/report/list"]);
  }
  openDetails(item: any) {
    this.dialog.open(VariableDetailsDialogComponent, {
      data: item
    });
  }
  onNoClick(): void {
    //this.dialogRef.close();
  }
}

class DataLayerWithColumns {
  Id: number | undefined
  Name: string = "";
  Schema: string = "";
  JoinType: string = "";
  DataLayerColumns: DataLayerColumn[] = [];
}

class DataLayerColumn {
  Name: string = "";
  DisplayName: string = "";
  Type: string = "";
  Alias: string = "";
  Hint: string = "";
}
