<div class="screen">
  <div class="d-flex flex-row align-items-center justify-content-between pt-2 pb-2 mb-2 border-bottom">
    <h1 class="h4 tab-header">Events</h1>
    <span>
      View by:
    </span>
    <mat-button-toggle-group name="viewType" [value]="viewType" hideSingleSelectionIndicator="true">
      <mat-button-toggle value="incident" class="view-toggle-button" (click)="setViewType('incident')">Event</mat-button-toggle>
      <mat-button-toggle value="asset" class="view-toggle-button" (click)="setViewType('asset')">Asset</mat-button-toggle>
    </mat-button-toggle-group>
    <span>
      View mode:
    </span>
    <mat-button-toggle-group name="mode" [value]="mode" hideSingleSelectionIndicator="true">
      <mat-button-toggle value="table" class="view-toggle-button" (click)="setMode('table')">Table</mat-button-toggle>
      <mat-button-toggle value="map" class="view-toggle-button" (click)="setMode('map', true)">Map</mat-button-toggle>
    </mat-button-toggle-group>
    <button class="btn btn-warning btn-sm download-excel-btn" (click)="downloadExcel()">
      <i-feather name="file" class="xsm"></i-feather>
      Export
    </button>
  </div>
  <div class="d-flex flex-row align-items-center">
    <event-metrics class="w-100" [filter]="filter"></event-metrics>
  </div>
  <div class="data-grid mt-1 h-100">
    <app-filter-panel [config]="filterConfig" (filterEvent)="filter = $event"></app-filter-panel>
    <app-grid #dataGrid class="w-100" [data]="items" [config]="gridConfig" [service]="service" [filter]="filter" [hidden]="mode!='table'" [loadData]="dataGridLoadData"></app-grid>
    <app-map class="w-100 h-100 pt-2" [showLayers]="true" [hidden]="mode!='map'" name="incidents"></app-map>
  </div>
</div>
