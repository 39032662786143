<h1 mat-dialog-title>Import assets</h1>
<mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Upload assets">
      <dashboard-upload
                        (onFileUploaded)="onFileUploaded($event[0], $event[1])"
                        (onFileProgress)="onFileProgress($event[0], $event[1], $event[2])"
                        (onCancelled) ="onCancelled($event[0])"
                        (onError)="onError($event[0], $event[1])"
                        (onRemoved)="onRemoved($event[0], $event[1])"></dashboard-upload>
    </mat-tab>
    <mat-tab label="Properties" [disabled]="!fileUploaded">
      <mat-form-field>
        <mat-label>Name column</mat-label>
        <mat-select [(value)]="nameColumn">
          <mat-option></mat-option>
          <mat-option [value]="option" *ngFor="let option of contentColumns">{{option}}</mat-option>
        </mat-select>
        <mat-hint>Optional</mat-hint>
      </mat-form-field>

      <fieldset class="p-2">
        <legend class="w-auto">Polygon</legend>

        <mat-form-field appearance="outline" class="colorpicker">
          <mat-label>Fill color</mat-label>
          <input matInput [(ngModel)]="properties.polygonColor" [mtxColorpicker]="polygonfillpicker" required>
          <mtx-colorpicker-toggle matSuffix [for]="polygonfillpicker"></mtx-colorpicker-toggle>
          <mtx-colorpicker #polygonfillpicker></mtx-colorpicker>
          <mat-hint class="transparent-bg"><div [style.background-color]="properties.polygonColor">&nbsp;</div></mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="colorpicker">
          <mat-label>Outline color</mat-label>
          <input matInput [(ngModel)]="properties.polygonOutlineColor" [mtxColorpicker]="polygonoutlinepicker" required>
          <mtx-colorpicker-toggle matSuffix [for]="polygonoutlinepicker"></mtx-colorpicker-toggle>
          <mtx-colorpicker #polygonoutlinepicker></mtx-colorpicker>
          <mat-hint class="transparent-bg"><div [style.background-color]="properties.polygonOutlineColor">&nbsp;</div></mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Outline width</mat-label>
          <input type="number" min="1" step="1" matInput [(ngModel)]="properties.polygonOutlineWidth" placeholder="Placeholder" required>
        </mat-form-field>
      </fieldset>
      <fieldset class="p-2">
        <legend class="w-auto">Line</legend>

        <mat-form-field appearance="outline" class="colorpicker">
          <mat-label>Color</mat-label>
          <input matInput [(ngModel)]="properties.lineColor" [mtxColorpicker]="linefillpicker" required>
          <mtx-colorpicker-toggle matSuffix [for]="linefillpicker"></mtx-colorpicker-toggle>
          <mtx-colorpicker #linefillpicker></mtx-colorpicker>
          <mat-hint class="transparent-bg"><div [style.background-color]="properties.lineColor">&nbsp;</div></mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Line width</mat-label>
          <input type="number" min="1" step="1" matInput [(ngModel)]="properties.lineWidth" placeholder="Placeholder" required>
        </mat-form-field>
      </fieldset>
      <fieldset class="p-2">
        <legend class="w-auto">Point</legend>

        <mat-form-field appearance="outline" class="colorpicker">
          <mat-label>Fill color</mat-label>
          <input matInput [(ngModel)]="properties.pointColor" [mtxColorpicker]="pointfillpicker" required>
          <mtx-colorpicker-toggle matSuffix [for]="pointfillpicker"></mtx-colorpicker-toggle>
          <mtx-colorpicker #pointfillpicker></mtx-colorpicker>
          <mat-hint class="transparent-bg"><div [style.background-color]="properties.pointColor">&nbsp;</div></mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="colorpicker">
          <mat-label>Outline color</mat-label>
          <input matInput [(ngModel)]="properties.pointOutlineColor" [mtxColorpicker]="pointoutlinepicker" required>
          <mtx-colorpicker-toggle matSuffix [for]="pointoutlinepicker"></mtx-colorpicker-toggle>
          <mtx-colorpicker #pointoutlinepicker></mtx-colorpicker>
          <mat-hint class="transparent-bg"><div [style.background-color]="properties.pointOutlineColor">&nbsp;</div></mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Size</mat-label>
          <input type="number" min="1" step="1" matInput [(ngModel)]="properties.pointWidth" placeholder="Placeholder" required>
        </mat-form-field>
      </fieldset>
      <fieldset class="p-2">
        <legend class="w-auto">Text</legend>

        <mat-form-field appearance="outline" class="colorpicker">
          <mat-label>Fill color</mat-label>
          <input matInput [(ngModel)]="properties.textColor" [mtxColorpicker]="textfillpicker" required>
          <mtx-colorpicker-toggle matSuffix [for]="textfillpicker"></mtx-colorpicker-toggle>
          <mtx-colorpicker #textfillpicker></mtx-colorpicker>
          <mat-hint class="transparent-bg"><div [style.background-color]="properties.textColor">&nbsp;</div></mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="colorpicker">
          <mat-label>Outline color</mat-label>
          <input matInput [(ngModel)]="properties.textOutlineColor" [mtxColorpicker]="textoutlinepicker" required>
          <mtx-colorpicker-toggle matSuffix [for]="textoutlinepicker"></mtx-colorpicker-toggle>
          <mtx-colorpicker #textoutlinepicker></mtx-colorpicker>
          <mat-hint class="transparent-bg"><div [style.background-color]="properties.textOutlineColor">&nbsp;</div></mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Scale</mat-label>
          <input type="number" min="1" step="1" matInput [(ngModel)]="properties.textScale" placeholder="Placeholder" required>
        </mat-form-field>
        <mat-checkbox [(ngModel)]="properties.textOverflow">Overflow</mat-checkbox>
      </fieldset>
    </mat-tab>
    <mat-tab label="Contents" [disabled]="!fileUploaded">
      <table class='table' aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th *ngFor="let column of contentColumns">{{column}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of [].constructor(!content.length ? 5 : 0)">
            <td *ngFor="let i of [].constructor(4)"><ngx-skeleton-loader count="1" [theme]="{margin: 0, display: 'block'}"></ngx-skeleton-loader></td>
          </tr>
          <tr *ngFor="let item of content">
            <td *ngFor="let key of contentColumns">{{ item[key] }}</td>
          </tr>
        </tbody>
      </table>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-stroked-button [disabled]="!enableImport" (click)="import()" cdkFocusInitial>Import</button>
</mat-dialog-actions>
<div class="loader-container" *ngIf="loading">
  <div class="loader-contents">
    <mat-spinner></mat-spinner>
  </div>
</div>
