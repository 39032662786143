import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserAccountsService {
  private readonly apiUrl = '/api/' + this.getResourceUrl();

  constructor(protected httpClient: HttpClient) {
  }

  getResourceUrl(): string {
    return 'UserAccounts';
  }

  getList(page: number = 0, count: number = 10, filter: any = {}, sort: any = {}): Observable<any> {
    let params = {
      "operationType": "SEARCH",
      "startRow": page * count,
      "pageSize": count,
      "updateCount": true,
      "sort":
      {
        "columnName": sort?.columnName,
        "sortDirectionText": sort?.order
      },
      "filter": filter
    }

    return this.httpClient.post<any[]>(`${this.apiUrl + "/GetList"}`, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getNonPagedList(filter: any = {}, sort: any = {}): Observable<any> {
    let params = {
      "operationType": "SEARCH",
      "updateCount": true,
      "sort":
      {
        "columnName": sort?.columnName,
        "sortDirectionText": sort?.order
      },
      "filter": filter
    }

    return this.httpClient.post<any[]>(`${this.apiUrl + "/GetNonPagedList"}`, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    // Handle the HTTP error here
    return throwError('Something wrong happened');
  }
}
