<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Template name</mat-label>
    <input matInput [(ngModel)]="data.name" required>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.name" cdkFocusInitial>Ok</button>
</div>
