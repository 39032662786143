import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {AuthenticationService} from "@/_services";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', './../login/login.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  error = '';
  submitted = false;
  sent = false;
  token: string | undefined;
  userid: string | undefined;
  returnUrl: string | undefined;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      'password-confirm': ['', ]
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.token = params['token'];
      this.userid = params['userid'];
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { // @ts-ignore
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    // @ts-ignore
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.resetPassword(this.f['password'].value, this.token, this.userid)
      .pipe(first())
      .subscribe({
        next: this.showMessage.bind(this),
        error: this.showError.bind(this)
      });
  }

  showMessage(data: any) {
    this.sent = true;
  }

  showError(err: any) {
    this.error = err;
    this.loading = false;
  }
}
