import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '@envs/environment';
import {map} from "rxjs/operators";
import { from } from "rxjs";

@Injectable({providedIn: 'root'})
export class LookupService {
  private lookupStore: any = {};

  constructor(private http: HttpClient) {}

  getLookup(id: string, searchTerm: string) {
    if(id in this.lookupStore) {
      return from([this.lookupStore[id]]);
    }
    else {
      return this.http.get<any>(`${environment.apiUrl}/api/lookup/get/${id}/${searchTerm}`, {}).pipe(map(result => {
        if(result.ok && result.items != null && result.items.length > 0) {
          this.lookupStore[id] = result.items;
          return result.items;
        }
        return null;
      }));
    }
  }
  clearLookupStore() {
    this.lookupStore = {};
  }
}
