import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { LookupService } from "@/_services/lookup.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from "ngx-bootstrap-multiselect";

@Component({
  selector: 'app-report-column-select',
  templateUrl: './report-column-select.component.html',
  styleUrls: ['./report-column-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReportColumnSelectComponent),
      multi: true
    }
  ]
})

export class ReportColumnSelectComponent implements OnInit, ControlValueAccessor {
  @Input() name: string = "";
  @Input() _selectedValue: any[] = [];
  @Input() mode: string = "multi";
  @Input() disabled: boolean = false;
  lookupData: any;
  public myOptions: IMultiSelectOption[] = [];
  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    this._selectedValue = val;
    this.propagateChange(this._selectedValue);
  }

  constructor(private lookupService: LookupService) { }

  // Settings configuration
  mySettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-outline-secondary btn-sm',
    dynamicTitleMaxItems: 1
  };

  ngOnInit(): void {
    this.lookupService.getLookup(this.name, "").subscribe((results: any) => {
      if (results != null) {
        this.lookupData = results;
        let tmpArr: IMultiSelectOption[] = [];
        this.lookupData.forEach(function (lookupData: any) {
          tmpArr.push({ id: lookupData.value, name: lookupData.label })
        });
        this.myOptions = tmpArr
      }
    });
    if(this.disabled) {
      this.mySettings.dynamicTitleMaxItems = 100;
      this.mySettings.buttonClasses = this.mySettings.buttonClasses + ' disabled';
    }
    else {
      this.mySettings.buttonClasses = this.mySettings.buttonClasses + ' btn-max-w-128';
    }
    if (this.mode == "single") {
      this.mySettings = Object.assign(this.mySettings, {
        selectionLimit: 1,
        autoUnselect: true,
        closeOnSelect: true,
        checkedStyle: undefined,
        showUncheckAll: true
      })
    }
    if (this.mode == "singlenn") {
      this.mySettings = Object.assign(this.mySettings, {
        selectionLimit: 1,
        minSelectionLimit: 1,
        autoUnselect: true,
        closeOnSelect: true,
        checkedStyle: undefined,
        showUncheckAll: false,
        selectAddedValues: true
      })
    }
  }

  writeValue(value: any) {
    this.selectedValue = value;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }
  optionsModel: number[] = [];
}
