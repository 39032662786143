import VectorLayer from "ol/layer/Vector";
import { MapLayerCategory } from "@/map/models";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import {Geometry} from "ol/geom";
import {Feature} from "ol";

export class MapLayer {
  public id: string | undefined;
  public name: string | undefined;
  public type: string | undefined;
  public description: string | undefined;
  public visible: boolean = false;
  public wkt: string | undefined;
  public expanded: boolean = true;
  public categories: MapLayerCategory[] | undefined;
  public olLayer: VectorLayer | TileLayer<TileWMS> | undefined;
  public total: number = 0;

  constructor() {
    this.visible = true;
  }
}
