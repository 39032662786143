export const defaultReportCompareViewGridConfigColDef = [
  {
    title: '',
    originalTitle: 'Show on Map',
    type: 'action',
    btnText: '',
    btnIconName: 'map',
    action: 'showOnMap',
    paramFields: ['id'],
    editable: true,
    visible: true
  },
  {
    title: "Asset name",
    originalTitle: "Asset name",
    field: "name",
    sortColumn: "AssetName",
    editable: true,
    visible: true,
  },
  {
    title: "Event Count Pass 1",
    originalTitle: "Event Count Pass 1",
    field: "incidentCountR1",
    sortColumn: "IncidentCountR1",
    editable: true,
    visible: true,
  },
  {
    title: "Event Count Pass 2",
    originalTitle: "Event Count Pass 2",
    field: "incidentCountR2",
    sortColumn: "IncidentCountR2",
    editable: true,
    visible: true,
  },
  {
    title: "Event count Diff",
    originalTitle: "Event count Diff",
    field: "incidentCountDiffrence",
    sortColumn: "IncidentCountDiffrence",
    editable: true,
    visible: true,
  },
  {
    title: "Min. horizontal distance Pass 1 [m]",
    originalTitle: "Min. horizontal distance Pass 1 [m]",
    field: "minimalDistanceToAssetR1",
    sortColumn: "MinimalDistanceToAssetR1",
    editable: true,
    visible: true,
  },
  {
    title: "Min. horizontal distance Pass 2 [m]",
    originalTitle: "Min. horizontal distance Pass 2 [m]",
    field: "minimalDistanceToAssetR2",
    sortColumn: "MinimalDistanceToAssetR2",
    editable: true,
    visible: true,
  },
  {
    title: "Min. horizontal distance Diff",
    originalTitle: "Min. horizontal distance Diff",
    field: "minimalDistanceToAssetDiffrence",
    sortColumn: "MinimalDistanceToAssetDiffrence",
    editable: true,
    visible: true,
  },
  {
    title: "Min. absolute distance Pass 1 [m]",
    originalTitle: "Min. absolute distance Pass 1 [m]",
    field: "minimalDistance3DToAssetR1",
    sortColumn: "MinimalDistance3DToAssetR1",
    editable: true,
    visible: true,
  },
  {
    title: "Min. absolute distance Pass 2 [m]",
    originalTitle: "Min. absolute distance Pass 2 [m]",
    field: "minimalDistance3DToAssetR2",
    sortColumn: "MinimalDistance3DToAssetR2",
    editable: true,
    visible: true,
  },
  {
    title: "Min. absolute distance Diff",
    originalTitle: "Min. absolute distance Diff",
    field: "minimalDistance3DToAssetDiffrence",
    sortColumn: "MinimalDistance3DToAssetDiffrence",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted length Pass 1 [m]",
    originalTitle: "Impacted length Pass 1 [m]",
    field: "estimatedAssetLengthImpactedR1",
    sortColumn: "EstimatedAssetLengthImpactedR1",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted length Pass 2 [m]",
    originalTitle: "Impacted length Pass 2 [m]",
    field: "estimatedAssetLengthImpactedR2",
    sortColumn: "EstimatedAssetLengthImpactedR2",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted length Diff [m]",
    originalTitle: "Impacted length Diff [m]",
    field: "impactDifference",
    sortColumn: "ImpactDifference",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted length Pass 1 [%]",
    originalTitle: "Impacted length Pass 1 [%]",
    field: "estimatedAssetPercentageImpactedR1",
    sortColumn: "EstimatedAssetPercentageImpactedR1",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted length Pass 2 [%]",
    originalTitle: "Impacted length Pass 2 [%]",
    field: "estimatedAssetPercentageImpactedR2",
    sortColumn: "EstimatedAssetPercentageImpactedR2",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted length Diff [%]",
    originalTitle: "Impacted length Diff [%]",
    field: "impactDifferencePrecent",
    sortColumn: "ImpactDifferencePrecent",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted Area Pass 1 [m2]",
    originalTitle: "Impacted Area Pass 1 [m2]",
    field: "estimatedAreaImpactedR1",
    sortColumn: "EstimatedAreaImpactedR1",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted Area Pass 2 [m2]",
    originalTitle: "Impacted Area Pass 2 [m2]",
    field: "estimatedAreaImpactedR2",
    sortColumn: "EstimatedAreaImpactedR2",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted Area Difference [m2]",
    originalTitle: "Impacted Area Difference [m2]",
    field: "estimatedAreaImpactedDiffrence",
    sortColumn: "EstimatedAreaImpactedDiffrence",
    editable: true,
    visible: true,
  },
  {
    title: "Impacted Area Percentage Pass 1 [%]",
    originalTitle: "Impacted Area Pass 1 [%]",
    field: "estimatedAreaPercentageImpactedR1",
    sortColumn: "EstimatedAreaImpactedPercentageSortR1",
    editable: true,
    visible: false,
  },
  {
    title: "Impacted Area Percentage Pass 2 [%]",
    originalTitle: "Impacted Area Pass 2 [%]",
    field: "estimatedAreaPercentageImpactedR2",
    sortColumn: "EstimatedAreaImpactedPercentageSortR2",
    editable: true,
    visible: false,
  },
  {
    title: "Impacted Area Percentage Difference [%]",
    originalTitle: "Impacted Area Difference [%]",
    field: "estimatedAreaImpactedPercentageDiffrence",
    sortColumn: "GrowthRate",
    editable: true,
    visible: false,
  },
  {
    title: "Total length of asset [m]",
    originalTitle: "Total length of asset [m]",
    field: "assetLength",
    sortColumn: "AssetLength",
    editable: true,
    visible: true,
  },
  {
    title: "Growth Rate [%/year]",
    originalTitle: "Growth Rate [%/year]",
    field: "growthRate",
    sortColumn: "GrowthRate",
    editable: true,
    visible: false,
  },
  {
    title: "Growth Rate2 [m2/year]",
    originalTitle: "Growth Rate2 [m2/year]",
    field: "growthRate2",
    sortColumn: "GrowthRate2",
    editable: true,
    visible: false,
  },
  {
    title: "Asset type",
    originalTitle: "Asset type",
    field: "assetType",
    sortColumn: "AssetType",
    editable: true,
    visible: false,
  },
  {
    title: "Asset Category",
    field: "assetCategory",
    originalTitle: "Asset Category",
    sortColumn: "AssetCategory",
    editable: true,
    visible: false,
  },
  {
    title: "Elevation",
    field: "elevation",
    originalTitle: "Elevation",
    sortColumn: "Elevation",
    editable: true,
    visible: false,
  },
  {
    title: "Asset Location",
    field: "assetLocation",
    originalTitle: "Asset Location",
    sortColumn: "AssetLocation",
    editable: true,
    visible: false,
  },
  {
    title: "Location subgroup",
    field: "assetSublocation",
    originalTitle: "Location subgroup",
    sortColumn: "AssetSublocation",
    editable: true,
    visible: false,
  },
  {
    title: "Start Point",
    field: "startName",
    originalTitle: "Start Point",
    sortColumn: "StartName",
    editable: true,
    visible: false,
  },
  {
    title: "End Point",
    field: "endName",
    originalTitle: "End Point",
    sortColumn: "EndName",
    editable: true,
    visible: false,
  }

];
