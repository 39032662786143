import { GridComponent } from "@/_components/grid";
import { ResourcesService } from "@/_services/resources.service";
import { AnalysisDataService } from "@/_services/analysis-data.service";
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalysisImportComponent } from '@/_components/analysis-import';
import { environment } from "@envs/environment";
import { MapLayer } from "@/map/models";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { MapLayerService } from "@/_services/map-layer.service";
import {ActivatedRoute, Router} from "@angular/router";
import { AnalysisMetrics } from "@/_components/analysis-metrics";
import WKB from "ol/format/WKB";
import WKT from "ol/format/WKT";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { AnalysisImportRasterComponent } from "@/_components/analysis-import-raster";
import { createEmpty, extend } from "ol/extent";
import { JsonResponse } from "@/_models/response";
import { ConfirmDialogComponent } from "@/_components/confirm-dialog";

@Component({
  selector: 'app-analysis',
  providers: [
    {
      provide: ResourcesService,
      useClass: AnalysisDataService
    }
  ],
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent {
  public items: Resource[] = [];
  http: HttpClient;
  public metrics: AnalysisMetrics;
  public gridConfig: any;
  public filter: any;
  public showDelete: boolean = false;
  @ViewChild('dataGrid') dataGrid!: GridComponent;

  constructor(
    private _http: HttpClient,
    private dialog: MatDialog,
    public _resourceService: ResourcesService,
    private _mapLayerService: MapLayerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.showDelete = (this.route.snapshot.queryParamMap.get('delete')) == "1";
    this.http = _http;
    this.refreshGrid();
    this.metrics = {
      latestAnalysisDate: new Date("2021-12-31"),
      analysesDone: 0,
      inspectedArea: 0,
      networkLength: 0,
      nextAnalysisDate: new Date("2022-12-31")
    };

    this.gridConfig = {
      _self: this,
      colDefs: [
        {
          title: "Name",
          field: "name",
          sortColumn: "name",
        },
        {
          title: "Type",
          field: "type",
          sortColumn: "type",
        },
        {
          title: "Location",
          field: "location",
          sortColumn: "location",
        },
        {
          title: "Analysis Date",
          field: "analysisDate",
          sortColumn: "analysisDate",
        },
        {
          title: '',
          type: 'action',
          btnText: '',
          btnIconName: 'map',
          btnTooltip: 'Show on map',
          action: this.showOnMap,
          paramFields: ['id']
        },
        {
          title: '',
          type: 'actionpossible',
          check: 'isWms',
          btnText: '',
          btnIconName: 'download',
          btnTooltip: 'Download',
          action: this.download,
          paramFields: ['id']
        }
      ],
      filters: [
        {
          field: "name",
          title: "Name",
          type: "string"
        },
        {
          field: "type",
          title: "Type",
          type: "string"
        },
        {
          field: "location",
          title: "Location",
          type: "string"
        },
        {
          field: "analysisDate",
          title: "Analysis Date",
          type: "date"
        }
      ],
      selectable: true
    }
    if(this.showDelete) {
      this.gridConfig.colDefs.push({
        title: '',
        type: 'action',
        btnText: '',
        btnIconName: 'trash',
        btnTooltip: 'Delete',
        action: this.remove,
        paramFields: ['id']
      })
    }
  }

  refreshGrid() {
    if (this.dataGrid != undefined) {
      this.dataGrid.refreshGrid();
    }
  }

  remove(item?: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        icon: "alert-triangle",
        message: "Are you sure to delete \"" + item.name + "\" data layer?",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.delete<JsonResponse>(`${environment.apiUrl}/api/AnalysisData/Delete?id=${item.id}`).subscribe(result => {
          if (result) {
            if (this.dataGrid != undefined) {
              this.dataGrid.refreshGrid();
            }
          }
        });
      }
    });
  }

  showOnMapMulti() {
    let extent = createEmpty();
    this.dataGrid.selectedItemsList.forEach((item: any, index) => {
      let layerExtent = this.showOnMap(item, item.id, false);
      if (layerExtent != undefined) {
        extend(extent, layerExtent);
      }
      this._mapLayerService.addEvent('', 'zoomToExtent', { extent: extent });
    });
    this.router.navigate(['/map']);
  }

  showOnMap(item: any, id: number, goToMap: boolean = true) {
    if (item.isWms) {
      let imageLayer = new MapLayer();
      imageLayer.name = item.name;
      imageLayer.id = "an-" + id;
      imageLayer.expanded = false;
      imageLayer.categories = item.categories;
      let extent = undefined;
      if (item.wkt != null) {
        let feature = new WKT().readFeature(item.wkt, { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' });
        extent = feature.getGeometry()?.getExtent();
      }
      imageLayer.olLayer = new TileLayer({
        extent: extent,
        preload: Infinity,
        source: new TileWMS({
          url: item.wmsUrl,
          params: { 'LAYERS': item.layers, 'TILED': true },
          transition: 0
        })
      });
      if (goToMap) {
        this._mapLayerService.addLayer(imageLayer, true);
        this.router.navigate(['/map']);
      }
      else {
        this._mapLayerService.addLayer(imageLayer);
      }
      return extent
    } else {
      item.loader = true;
      let self = this;
      this._http.post<any>(`${environment.apiUrl}/api/AnalysisData/GetMapData`, { Id: id }).subscribe({
        next: (result: any) => {
          var mapData = result.item;
          var layer = new MapLayer();
          layer.id = "an-" + id;
          layer.categories = mapData.mapLayers;
          layer.name = mapData.name;
          layer.expanded = false;
          layer.visible = true;

          let _vectorSource = new VectorSource();
          let _wkbFormatter = new WKB();

          mapData.data.forEach(function (value: any) {
            let _feature = _wkbFormatter.readFeature(value.data, {
              dataProjection: 'EPSG:3857',
              featureProjection: 'EPSG:3857',
            });

            _feature.setStyle(getOlStyle(value.pxlval, layer));
            _vectorSource.addFeature(_feature);
          });

          layer.olLayer = new VectorLayer({ source: _vectorSource });
          if (goToMap) {
            self._mapLayerService.addLayer(layer, true);
            this.router.navigate(['/map']);
          }
          else {
            self._mapLayerService.addLayer(layer);
          }
          item.loader = false;
        },
        error: (error: Error) => console.error(error)
      });

      let getOlStyle = function (pxlval: any, layer: MapLayer) {
        var cat = layer.categories?.find(x => x.pxlval == pxlval || x.expression == pxlval);
        if (cat?.geometryType == 'point') {
          return new Style({
            image: new Circle({
              radius: cat?.outlineWidth,
              fill: new Fill({
                color: cat?.color,
              }),
              stroke: new Stroke({
                color: cat?.outlineColor,
                width: cat?.outlineWidth,
              })
            })
          });
        } else {
          return new Style({
            fill: new Fill({
              color: cat?.color,
            }),
            stroke: new Stroke({
              color: cat?.outlineColor,
              width: cat?.outlineWidth,
            }),
          });
        }
      };

      return undefined;
    }
  }
  openUpload() {
    const dialogRef = this.dialog.open(AnalysisImportComponent, {
      data: {},
      width: '60vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshGrid();
    });
  }

  openUploadRaster() {
    const dialogRef = this.dialog.open(AnalysisImportRasterComponent, {
      data: {},
      width: '60vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshGrid();
    });
  }

  download(item?: any) {
    this.http.get<any>(`${environment.apiUrl}/api/AnalysisData/GetFileLink?id=` + item.id).subscribe({
      next: (result: any) => {
        const url = result.item;
        const link = document.createElement('a');
        link.href = url;
        //link.setAttribute('download', result.item.fileName);
        document.body.appendChild(link);
        link.click();
      },
      error: (error: Error) => console.error(error)
    });
  }
}

interface Resource {
  id: number
  name: string
  location: string
  share: string
  type: string
  size: number
  status: number
}
