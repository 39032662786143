import { Injectable } from '@angular/core';
import { environment } from '@envs/environment';
import { Observable, Subject, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsLookupsService {
  protected readonly apiUrl = '/api/Report';
  protected getLayersEndpoint = "/GetDataLayersWithColumns";
  protected getLayersRequest$ = new Subject();
  protected getLayersResponse$ = new Subject();

  constructor(protected httpClient: HttpClient) {
    this.getLayersRequest$.pipe(switchMap(
      params => {
        return this.httpClient.post<any[]>(`${this.apiUrl + this.getLayersEndpoint}`, params)
          .pipe(
            catchError(this.handleError)
          )
      })).subscribe(results => this.getLayersResponse$.next(results));
  }

  getList(filter: any = null): Observable<any> {
    let params = {
      "filter": filter,
    }
    this.getLayersRequest$.next(params);
    return this.getLayersResponse$.asObservable();
  }

  protected handleError(error: HttpErrorResponse) {
    return throwError('Something wrong happened');
  }
}
