import { AssetsService } from "@/_services/assets.service";
import { ResourcesService } from "@/_services/resources.service";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetsImportComponent } from '@/_components/assets-import';
import { MapLayerService } from "@/_services/map-layer.service";
import { MapLayer } from "@/map/models";
import VectorSource from "ol/source/Vector";
import WKB from "ol/format/WKB";
import {Style, Stroke, Fill, Text as olText, Circle, RegularShape} from "ol/style";
import VectorLayer from "ol/layer/Vector";
import { Router } from "@angular/router";
import { Color } from "ol/color";
import { OlMapService } from "@/_services/ol-map.service";
import { DataDialogComponent } from "@/_components/data-dialog";
import {LineString, Point} from "ol/geom";

@Component({
  selector: 'app-assets',
  providers: [
    {
      provide: ResourcesService,
      useClass: AssetsService
    }
  ],
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  public items: any[] = [];
  public gridConfig: any;
  public filterConfig: any;
  public mapDataLoading: boolean = false;
  private http: HttpClient;
  private _filter: any;
  public viewType: string = 'table';

  constructor(http: HttpClient, private dialog: MatDialog, public _assetsService: AssetsService, private _mapLayerService: MapLayerService, private router: Router, private _olMapService: OlMapService) {
    this.gridConfig = {
      _self: this,
      colDefs: [
        {
          title: '',
          type: 'action',
          btnText: '',
          btnIconName: 'map',
          btnTooltip: 'Show on map',
          action: this.showOnMap,
          paramFields: ['id']
        },
        {
          title: "Asset Name",
          field: "name",
          sortColumn: "name",
        },
        {
          title: "Asset Type",
          field: "assetType",
          sortColumn: "assetType",
        },
        {
          title: "Asset Category",
          field: "assetCategory",
          sortColumn: "assetCategory",
        },
        {
          // title: "Asset Category",
          title: "Elevation",
          field: "elevation",
          sortColumn: "elevation",
        },
        {
          title: "Asset Location",
          field: "assetLocation",
          sortColumn: "assetLocation",
        },
        {
          title: "Location Subgroup",
          field: "assetSublocation",
          sortColumn: "assetSublocation",
        },
        {
          title: "Start Point",
          field: "startName",
          sortColumn: "startName",
        },
        {
          title: "End Point",
          field: "endName",
          sortColumn: "endName",
        },

        {
          title: '',
          type: 'action',
          btnText: 'Details',
          btnTooltip: 'Show more details',
          action: this.openDetails,
          paramFields: ['additionalColumns']
        }
      ]
    }
    this.filterConfig = [
      {
        field: "name",
        title: "Asset Name",
        type: "string",
      },
      {
        title: "Asset Type",
        field: "assetType",
        type: "string",
      },
      {
        field: "assetCategory",
        title: "Asset Category",
        type: "string",
      },
      {
        title: "Elevation",
        field: "elevation",
        type: "number",
      },
      {
        field: "assetLocation",
        title: "Asset Location",
        type: "string",
      },
      {
        field: "assetSublocation",
        title: "Location Subgroup",
        type: "string",
      },
      {
        title: "Start Point",
        field: "startName",
        type: "string",
      },
      {
        title: "End Point",
        field: "endName",
        type: "string",
      },
    ];
    this.http = http;
  }

  set filter(value: any) {
    this._filter = value;
    this.makeLayerAssets();
  }

  get filter() {
    return this._filter;
  }

  public setViewType(type: string, zoomToLayer: boolean = false) {
    this.viewType = type;
    if (type == 'map') {
      this._olMapService.addEvent('assets', 'updateSize', {});
      if (zoomToLayer) {
        this._mapLayerService.addEvent('assets', 'zoomToLayer', { layerId: "assets" });
      }
    }
  }

  private makeLayerAssets() {
    this._mapLayerService.addEvent('assets', 'showLoader', {})
    let layer = new MapLayer();
    this.mapDataLoading = true;
    layer.id = "assets";
    this._assetsService.getAssetsForMap(this.filter, {}).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.name = "Assets";
        layer.visible = true;
        layer.id = "assets";
        layer.total = result.items.length;
        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          _feature.setId(value.id);
          _feature.setProperties({
            popup: JSON.stringify({
              'Name': value.name,
            }, undefined, 2)
          })

          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['Point'].includes(geometryType!)) {
            var pointGeom = <Point>_feature.getGeometry();
            var coord = pointGeom.getCoordinates();
            var startPoint = coord;
            var endPoint = [coord[0] + 0.1, coord[1] + 0.1]; // Create a short line
            _feature.setGeometry(new LineString([startPoint, endPoint]));
            geometry = _feature.getGeometry();
            geometryType = geometry?.getType();
          }

          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                image: new Circle({
                  radius: value.properties.pointWidth,
                  fill: new Fill({
                    color: value.properties.pointColor
                  }),
                  stroke: new Stroke({
                    color: value.properties.pointOutlineColor,
                    width: 1
                  })
                })
              })
            );
          }
          else if (['Polygon', 'MultiPolygon', 'GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        layer.olLayer.set('layerId', layer.id);
        this._mapLayerService.addLayer(layer, false);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('assets', 'hideLoader', {})
        //this.router.navigate(['/map']);
      },
      error: (error: Error) => console.error(error)
    });
    return layer;
  }

  openUpload() {
    const dialogRef = this.dialog.open(AssetsImportComponent, {
      data: {},
      width: '60vw',
      height: '80vh',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log(result);
    });
  }

  ngOnInit(): void {}

  openDetails(item: any) {
    this.dialog.open(DataDialogComponent, {
      data: {
        title: item.name,
        dataTable: item.additionalColumns
      }
    });
  }

  showOnMap(item?: any) {
    if (item != null) {
      this.setViewType("map");
      this._olMapService.addEvent('assets', 'selectFeature', { layerId: 'assets', featureId: item.id })
      this._mapLayerService.addEvent('assets', 'zoomToFeature', { layerId: 'assets', featureId: item.id })
    }
    else {
      this.makeLayerAssets();
    }
  }
}
