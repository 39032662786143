import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "@/_services";
import {first} from "rxjs/operators";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss', './../login/login.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  sent = false;
  error = '';
  submitted = false;
  returnUrl: string | undefined;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { // @ts-ignore
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    // @ts-ignore
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.f['username'].value)
      .pipe(first())
      .subscribe({
        next: this.showMessage.bind(this),
        error: this.showError.bind(this)
      });
  }

  showMessage(data: any) {
    this.sent = true;
  }

  showError(err: any) {
    this.error = err;
    this.loading = false;
  }
}
