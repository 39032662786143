import { Component, Input, Output, OnInit, EventEmitter, forwardRef, reflectComponentType } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterInputComponent),
      multi: true
    }
  ]
})

export class FilterInputComponent implements OnInit, ControlValueAccessor {
  public _selectedValue: filterValue | undefined;
  @Input() type: string = "";
  @Input() disabled: boolean = false;
  public operator: number = 1;
  public _valuePrimary: any;
  public _valueSecondary: any;

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(val) {
    if (val != undefined) {
      this._selectedValue = val;
      this.valuePrimary = this._selectedValue.valuePrimary;
      this.valueSecondary = this._selectedValue.valueSecondary;
      this.operator = this._selectedValue.operator;
      this.propagateChange(this._selectedValue);
    }
  }

  get valuePrimary() {
    return this._valuePrimary;
  }
  set valuePrimary(val: any) {
    this._valuePrimary = val;
    if (this._selectedValue != undefined) {
      this._selectedValue.valuePrimary = undefined;
      if (this.type == "number" && (val === "" || val === null)) {
        this._selectedValue.valuePrimary = null;
      }
      else if (this.type == "number") {
        this._selectedValue.valuePrimary = parseFloat(this.valuePrimary) as number;
      }
      else if (this.type == "date" && val != "" && val instanceof Date) {
        this._selectedValue.valuePrimary = (new Date(Date.UTC(this.valuePrimary.getFullYear(), this.valuePrimary.getMonth(), this.valuePrimary.getDate(), 0, 0, 0))).toISOString();
      }
      else {
        this._selectedValue.valuePrimary = this.valuePrimary;
      }
    }
    this.propagateChange(this._selectedValue);
  }

  get valueSecondary() {
    return this._valueSecondary;
  }

  set valueSecondary(val: any) {
    this._valueSecondary = val;
    if (this._selectedValue != undefined) {
      this._selectedValue.valueSecondary = undefined;
      if (this.type == "number" && val == "") {
        this._selectedValue.valueSecondary = null;
      }
      else if (this.type == "number") {
        this._selectedValue.valueSecondary = parseFloat(this.valueSecondary) as number;
      }
      else if (this.type == "date" && val != "" && val instanceof Date) {
        this._selectedValue.valueSecondary = (new Date(Date.UTC(this.valueSecondary.getFullYear(), this.valueSecondary.getMonth(), this.valueSecondary.getDate(), 0, 0, 0))).toISOString();
      }
      else {
        this._selectedValue.valueSecondary = this._valueSecondary;
      }
    }
    this.propagateChange(this._selectedValue);
  }

  constructor() { }

  ngOnInit(): void {
  }

  setOperator(operator: number) {
    this.operator = operator;
    if (this._selectedValue != undefined) {
      this._selectedValue.operator = this.operator;
    }
    this.propagateChange(this._selectedValue);
  }

  writeValue(value: any) {
    if (value == undefined) {
      value = {
        operator: this.getDefaultOperator()
      };
    }
    this.selectedValue = value;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  getDefaultOperator(): number {
    if (this.type == "text") {
      return 7;
    }
    return 1;
  }
}
class filterValue {
  operator: number = 1
  valuePrimary: number | string | undefined | null
  valueSecondary: number | string | undefined | null
}
