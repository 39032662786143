export const defaultReportEventViewGridConfigColDef = [
  {
    title: '',
    originalTitle: 'Show on Map',
    type: 'action',
    btnText: '',
    btnIconName: 'map',
    btnTooltip: 'Show on map',
    action: 'showOnMap',
    paramFields: ['id'],
    editable: true,
    visible: true,
  },
  {
    title: "Event Id",
    originalTitle: "Event Id",
    field: "incidentName",
    sortColumn: "IncidentName",
    editable: true,
    visible: true,
  },
  {
    title: "Asset Name",
    originalTitle: "Asset Name",
    field: "assetName",
    sortColumn: "Asset.Name",
    editable: true,
    visible: true,
  },
  {
    title: "Variable",
    originalTitle: "Variable",
    field: "class",
    sortColumn: "IncidentClassLabel",
    editable: true,
    visible: true,
  },
  {
    title: "Horizontal distance [m]",
    originalTitle: "Horizontal distance [m]",
    field: "distance",
    sortColumn: "AssetDistance",
    editable: true,
    visible: true,
  },
  {
    title: "Absolute distance [m]",
    originalTitle: "Absolute distance [m]",
    field: "distance3D",
    sortColumn: "AssetDistance3D",
    editable: true,
    visible: true,
  },
  {
    title: "Ndvi",
    originalTitle: "Ndvi",
    field: "ndvi",
    sortColumn: "NDVI",
    editable: true,
    visible: true,
  },
  {
    title: "Height estimate [m]",
    originalTitle: "Height estimate [m]",
    field: "height",
    sortColumn: "Height",
    editable: true,
    visible: true,
  },
  {
    title: "Area [m2]",
    originalTitle: "Area [m2]",
    field: "area",
    sortColumn: "Area",
    editable: true,
    visible: true,
  },
  {
    title: "Estimated production capacity [kW]",
    field: "estimatedSolarProductionCapacity",
    originalTitle: "Estimated production capacity",
    sortColumn: "EstimatedSolarProductionCapacity",
    editable: true,
    visible: false,
  },
  {
    title: "Date",
    originalTitle: "Date",
    field: "date",
    sortColumn: "Date",
    editable: true,
    visible: true,
  },
  {
    title: "Asset type",
    originalTitle: "Asset type",
    field: "assetType",
    sortColumn: "Asset.AssetType",
    editable: true,
    visible: false,
  },
  {
    title: "Total length of asset [m]",
    originalTitle: "Total length of asset [m]",
    field: "assetLength",
    sortColumn: "Asset.AssetLength",
    editable: true,
    visible: false,
  },
  {
    title: "Asset Category",
    field: "assetCategory",
    originalTitle: "Asset Category",
    sortColumn: "Asset.AssetCategory",
    editable: true,
    visible: false,
  },
  {
    title: "Elevation",
    field: "elevation",
    originalTitle: "Elevation",
    sortColumn: "Asset.Elevation",
    editable: true,
    visible: false,
  },
  {
    title: "Asset Location",
    field: "assetLocation",
    originalTitle: "Asset Location",
    sortColumn: "Asset.AssetLocation",
    editable: true,
    visible: false,
  },
  {
    title: "Location subgroup",
    field: "assetSublocation",
    originalTitle: "Location subgroup",
    sortColumn: "Asset.AssetSublocation",
    editable: true,
    visible: false,
  },
  {
    title: "Start Point",
    field: "startName",
    originalTitle: "Start Point",
    sortColumn: "Asset.StartName",
    editable: true,
    visible: false,
  },
  {
    title: "End Point",
    field: "endName",
    originalTitle: "End Point",
    sortColumn: "Asset.EndName",
    editable: true,
    visible: false,
  }
];
