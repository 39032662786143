import { Injectable } from '@angular/core';
import { environment } from '@envs/environment';
import { Observable, Subject, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  protected readonly apiUrl = '/api/' + this.getResourceUrl();
  protected getListEndpoint = "/GetList";
  protected getNonPagedListEndpoint = "/GetNonPagedList";
  protected getMetricsEndpoint = "/GetStats";
  protected getListRequest$ = new Subject();
  protected getListResponse$ = new Subject();
  protected getNonPagedListRequest$ = new Subject();
  protected getNonPagedListResponse$ = new Subject();
  protected getMetricsRequest$ = new Subject();
  protected getMetricsResponse$ = new Subject();
  constructor(protected httpClient: HttpClient) {
    this.getListRequest$.pipe(switchMap(
      params => {
        return this.httpClient.post<any[]>(`${this.apiUrl + this.getListEndpoint}`, params)
          .pipe(
            catchError(this.handleError)
          )
      })).subscribe(results => this.getListResponse$.next(results));
    this.getNonPagedListRequest$.pipe(switchMap(
      params => {
        return this.httpClient.post<any[]>(`${this.apiUrl + this.getNonPagedListEndpoint}`, params)
          .pipe(
            catchError(this.handleError)
          )
      })).subscribe(results => this.getNonPagedListResponse$.next(results));
    this.getMetricsRequest$.pipe(switchMap(
      params => {
        return this.httpClient.post<any[]>(`${this.apiUrl + this.getMetricsEndpoint}`, params)
          .pipe(
            catchError(this.handleError)
          )
      })).subscribe(results => this.getMetricsResponse$.next(results));
  }
  getResourceUrl(): string {
    return 'Resource';
  }
  getList(page: number = 0, count: number = 15, filter: any = {}, sort: any = {}, parentId: any = null): Observable<any> {
    let params = {
      "operationType": "SEARCH",
      "startRow": page * count,
      "pageSize": count,
      "updateCount": true,
      "sort": {
        "columnName": sort?.columnName,
        "sortDirectionText": sort?.order
      },
      "filter": filter,
      "parentId": parentId
    }
    this.getListRequest$.next(params);
    return this.getListResponse$.asObservable();
  }
  getNonPagedList(filter: any = {}, sort: any = {}, parentId: any = null): Observable<any> {
    let params = {
      "operationType":"SEARCH",
      "updateCount":true,
      "pageSize": -1,
      "sort": {
        "columnName": sort?.columnName,
        "sortDirectionText": sort?.order
      },
      "filter": filter,
      "parentId": parentId
    }
    this.getNonPagedListRequest$.next(params);
    return this.getNonPagedListResponse$.asObservable();
  }
  getMetrics(filter: any = {}, sort: any = {}): Observable<any> {
    let params = {
      "operationType": "SEARCH",
      "updateCount": true,
      "sort":
      {
        "columnName": sort?.columnName,
        "sortDirectionText": sort?.order
      },
      "filter": filter
    }
    this.getMetricsRequest$.next(params);
    return this.getMetricsResponse$.asObservable();
  }
  protected handleError(error: HttpErrorResponse) {
    return throwError('Something wrong happened');
  }
}
