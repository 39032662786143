import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { MapLayer } from "@/map/models";
import { moveItemInArray } from '@angular/cdk/drag-drop';
import {OlMapEvent} from "@/_models/ol-map-event";

@Injectable({
  providedIn: 'root'
})
export class MapLayerService {
  private _layers: BehaviorSubject<Array<MapLayer>> = new BehaviorSubject(new Array<MapLayer>());
  public zoomToLayer: MapLayer | null = null;
  public readonly layers: Observable<Array<MapLayer>> = this._layers.asObservable();

  private _event: BehaviorSubject<OlMapEvent> = new BehaviorSubject(new OlMapEvent());
  public readonly event: Observable<OlMapEvent> = this._event.asObservable();

  public addEvent(target: string, action: string, data: any) {
    let event = new OlMapEvent();
    event.target = target;
    event.action = action;
    event.data = data
    this._event.next(event);
  }

  public addLayer(layer: MapLayer, zoomTo: boolean = false, addAtTheEnd: boolean = false) {
    let currentValue = this._layers.getValue();
    if (!currentValue.some(e => e.id == layer.id)) {
      if(addAtTheEnd) {
        currentValue.push(layer);
      }
      else {
        currentValue.unshift(layer);
      }
    }
    else {
      currentValue = currentValue.map(l => (l.id != layer.id) ? l : layer);
    }
    if(zoomTo) {
      this.zoomToLayer = layer;
    }
    else {
      this.zoomToLayer = null;
    }
    this._layers.next(currentValue);
  }

  public updateOrder(previousIndex: number, currentIndex: number) {
    let currentValue = this._layers.getValue();
    moveItemInArray(currentValue, previousIndex, currentIndex);
    this._layers.next(currentValue);
  }

  public clear() {
    this._layers.next(new Array<MapLayer>());
  }
}
