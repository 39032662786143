import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Material Form Controls
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
//import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
//import { MatSliderModule } from '@angular/material/slider';
//import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Material Navigation
//import { MatMenuModule } from '@angular/material/menu';
//import { MatSidenavModule } from '@angular/material/sidenav';
//import { MatToolbarModule } from '@angular/material/toolbar';
// Material Layout
//import { MatCardModule } from '@angular/material/card';
//import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
//import { MatGridListModule } from '@angular/material/grid-list';
//import { MatListModule } from '@angular/material/list';
//import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
//import { MatTreeModule } from '@angular/material/tree';
// Material Buttons & Indicators
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
//import { MatBadgeModule } from '@angular/material/badge';
//import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
//import { MatRippleModule } from '@angular/material/core';
// Material Popups & Modals
//import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
//import { MatSnackBarModule } from '@angular/material/snack-bar';
//import { MatTooltipModule } from '@angular/material/tooltip';
// Material Data tables
//import { MatPaginatorModule } from '@angular/material/paginator';
//import { MatSortModule } from '@angular/material/sort';
//import { MatTableModule } from '@angular/material/table';
//CDK
import { DragDropModule } from '@angular/cdk/drag-drop';

//Extensions
//import { MtxAlertModule } from '@ng-matero/extensions/alert';
//import { MtxCheckboxGroupModule } from '@ng-matero/extensions/checkbox-group';
import { MtxColorpickerModule } from '@ng-matero/extensions/colorpicker';
//import { MtxGridModule } from '@ng-matero/extensions/grid';
//import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxDialogModule } from '@ng-matero/extensions/dialog';
//import { MtxDrawerModule } from '@ng-matero/extensions/drawer';
//import { MtxLoaderModule } from '@ng-matero/extensions/loader';
//import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { MtxProgressModule } from '@ng-matero/extensions/progress';
//import { MtxSelectModule } from '@ng-matero/extensions/select';
//import { MtxSliderModule } from '@ng-matero/extensions/slider';
//import { MtxSplitModule } from '@ng-matero/extensions/split';
//import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    //MatAutocompleteModule,
    MatCheckboxModule,
    //MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    //MatSliderModule,
    //MatSlideToggleModule,
    //MatMenuModule,
    // MatSidenavModule,
    // MatToolbarModule,
    // MatCardModule,
    // MatDividerModule,
    MatExpansionModule,
    // MatGridListModule,
    // MatListModule,
    // MatStepperModule,
    MatTabsModule,
    // MatTreeModule,
    MatButtonModule,
    MatButtonToggleModule,
    // MatBadgeModule,
    // MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    // MatRippleModule,
    // MatBottomSheetModule,
    MatDialogModule,
    // MatSnackBarModule,
    // MatTooltipModule,
    // MatPaginatorModule,
    // MatSortModule,
    // MatTableModule,
    DragDropModule,
    // MtxAlertModule,
    //MtxCheckboxGroupModule,
    MtxColorpickerModule,
    // MtxGridModule,
    //MtxDatetimepickerModule,
    MtxDialogModule,
    // MtxDrawerModule,
    //MtxLoaderModule,
    // MtxPopoverModule,
    MtxProgressModule,
    // MtxSelectModule,
    // MtxSliderModule,
    // MtxSplitModule,
    // MtxTooltipModule
  ],
  exports: [
    // MatAutocompleteModule,
    MatCheckboxModule,
    //MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    //MatSliderModule,
    //MatSlideToggleModule,
    // MatMenuModule,
    // MatSidenavModule,
    // MatToolbarModule,
    // MatCardModule,
    // MatDividerModule,
    MatExpansionModule,
    // MatGridListModule,
    // MatListModule,
    // MatStepperModule,
    MatTabsModule,
    // MatTreeModule,
    MatButtonModule,
    MatButtonToggleModule,
    // MatBadgeModule,
    // MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    //MatProgressBarModule,
    // MatRippleModule,
    // MatBottomSheetModule,
    MatDialogModule,
    // MatSnackBarModule,
    // MatTooltipModule,
    // MatPaginatorModule,
    // MatSortModule,
    // MatTableModule,
    DragDropModule,
    // MtxAlertModule,
    //MtxCheckboxGroupModule,
    MtxColorpickerModule,
    // MtxGridModule,
    // MtxDatetimepickerModule,
    MtxDialogModule,
    // MtxDrawerModule,
    // MtxLoaderModule,
    // MtxPopoverModule,
    MtxProgressModule,
    // MtxSelectModule,
    // MtxSliderModule,
    // MtxSplitModule,
    // MtxTooltipModule
  ]
})
export class MaterialModule { }
