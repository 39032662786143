<div class="row">
  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Total # assets</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.total}}</span>
      </span>
    </div>
  </div>
  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Total # point assets</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.totalPoints}}</span>
      </span>
    </div>
  </div>
  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Total km line assets</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.totalLength}}</span>
      </span>
    </div>
  </div>
  <div class="col-3">
    <div class="h-100 p-1 d-flex flex-column border rounded-3 text-center">
      <h6>Total km<sup>2</sup> land assets</h6>
      <span class="display-6">
        <ngx-skeleton-loader count="1" [theme]="{margin: 0, width: '40%', height: '100%'}" *ngIf="loading"></ngx-skeleton-loader>
        <span *ngIf="!loading">{{data.totalArea}}</span>
      </span>
    </div>
  </div>
</div>
