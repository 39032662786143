import { getMultipleValuesInSingleSelectionError } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { environment } from '@envs/environment';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from "ngx-bootstrap-multiselect";
import { DialogService } from '@/_services/dialog.service';
import { CompanyConfigService } from "@/_services/company.config";

export class Variable {
  constructor() {
    this.selectedFilters = [];
  }

  id: number | undefined;
  name: string | undefined;
  description: string | undefined;
  incidentFilter: string | undefined;
  whereString: string | undefined;
  distance: number | undefined;
  selectedFilters: any[];
  types: any;
}

@Component({
  selector: 'create-variable',
  styleUrls: ['create-variable.component.scss'],
  templateUrl: 'create-variable.component.html',
})
export class CreateVariableComponent {
  lookupData: any;
  public filtersList: IMultiSelectOption[];
  private _filterToAdd: any;

  public filter: any;

  public entity: Variable;

  public createLoader: boolean = false;
  //private dialogRef: MatDialogRef<CreateVariableComponent>,

  set filterToAdd(value) {
    this._filterToAdd = value;
    if (this._filterToAdd == "height") {
      this.filter = {
        field: "Height",
        title: "Height",
        type: "number",
        value: { operator: 4, valuePrimary: null, valueSecondary: null }
      };
    } else if (this._filterToAdd == "IncidentClassId") {
      this.filter = {
        field: "IncidentClassId",
        title: "Parent variable",
        type: "lookup-multi",
        lookupName: "INCIDENTTYPE"
      }
    } else if (this._filterToAdd == "area") {
      this.filter = {
        field: "Area",
        title: "Area",
        type: "numberlimited",
        operator: 4
      };
    } else if (this._filterToAdd == "NDVI") {
      this.filter = {
        field: "NDVI",
        title: "Ndvi",
        type: "number",
        value: { operator: 6, valuePrimary: null, valueSecondary: null }
      };
    } else if (this._filterToAdd == "distance") {
      this.filter = {
        field: "Distance",
        title: "Horizontal distance [m]",
        type: "numberlimited",
        value: { operator: 6, valuePrimary: null, valueSecondary: null }
      };
    }
    else if (this._filterToAdd == "distance3d") {
      this.filter = {
        field: "AssetDistance3D",
        title: "Absolute distance [m]",
        type: "numberlimited",
        value: { operator: 6, valuePrimary: null, valueSecondary: null }
      };
    }
  }

  get filterToAdd() {
    return this._filterToAdd;
  }

  http: HttpClient;
  private _companyConfig: any;

  //@ViewChild("createRepotTabs", { static: false }) createRepotTabs: MatTabGroup | undefined;

  constructor(
    public dialogRef: MatDialogRef<CreateVariableComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _http: HttpClient,
    public dialogService: DialogService,
    private _companyConfigService: CompanyConfigService,
  ) {
    this.http = _http;
    this.entity = new Variable();

    this.entity.selectedFilters = [
      {
        field: "Height",
        title: "Height [m]",
        type: "number",
        value: { operator: 4, valuePrimary: null, valueSecondary: null }
      }, {
        field: "Area",
        title: "Area [m2]",
        type: "numberlimited",
        operator: 4
      },
      {
        field: "NDVI",
        title: "Ndvi",
        type: "number",
        value: { operator: 6, valuePrimary: null, valueSecondary: null }
      }
    ];

    let tmpArr: IMultiSelectOption[] = [];
    tmpArr.push({ id: "distance", name: "Horizontal distance [m]" })
    this.entity.selectedFilters.push({
      field: "Distance",
      title: "Horizontal distance [m]",
      type: "numberlimited",
      value: { operator: 6, valuePrimary: null, valueSecondary: null }
    });

    this._companyConfigService.configStore.subscribe(
      config => {
        if (this._companyConfigService.configLoaded) {
          this._companyConfig = config;
          if (this._companyConfig?.showAbsoluteDistance) {
            this.entity.selectedFilters.push({
              field: "AssetDistance3D",
              title: "Absolute distance [m]",
              type: "numberlimited",
              value: { operator: 6, valuePrimary: null, valueSecondary: null }
            });

            tmpArr.push({ id: "distance3d", name: "Absolute distance [m]" })
          }
        }
      }
    )
    tmpArr.push({ id: "height", name: "Height estimate [m]" })
    //tmpArr.push({ id: "IncidentClassId", name: "Type" })
    tmpArr.push({ id: "area", name: "Area" })
    tmpArr.push({ id: "NDVI", name: "Ndvi" })

    this.filtersList = tmpArr;
  }

  filtersListSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-outline-secondary btn-sm btn-max-w-128',
    dynamicTitleMaxItems: 1,
    selectionLimit: 1,
    autoUnselect: true,
    closeOnSelect: true,
    checkedStyle: undefined,
    showUncheckAll: false
  };

  filtersListTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Clear',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Pick filter',
    allSelected: 'All selected',
  };

  addCurrentFilter() {
    if (this.filter && this.filter.type) {
      if (this.filter.type == "lookup-multi") {
        this.entity.types = this.filter.value;
      } else {
        this.entity.selectedFilters.push(this.filter);
        var currentfilter = this._filterToAdd;
        this.filtersList = this.filtersList.filter(function (item) {
          return item.id !== currentfilter[0];
        });
      }
    }
    this.filter = {};
  }

  create() {
    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/Variable/Create/`, this.entity).subscribe(result => {
        if (result.ok) {
          this.dialogRef.close();
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
        }
        this.createLoader = false;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
