<h1 mat-dialog-title>Import analysis raster</h1>
<mat-dialog-content>
  <mat-tab-group #shpUploadTabs>
    <mat-tab label="Upload analysis raster">
      <dashboard-upload (onFileUploaded)="onFileUploaded($event[0], $event[1])"
                        (onFileProgress)="onFileProgress($event[0], $event[1], $event[2])"
                        (onCancelled)="onCancelled($event[0])"
                        (onError)="onError($event[0], $event[1])"
                        (onRemoved)="onRemoved($event[0], $event[1])"></dashboard-upload>
      <mat-form-field appearance="outline" class="m-2">
        <mat-label>Analysis name</mat-label>
        <input matInput [(ngModel)]="analysisName" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="m-2">
        <mat-label>Analysis date</mat-label>
        <input matInput type="text" [(ngModel)]="analysisDate" placeholder="yyyy-mm-dd" class="input-date" bsDatepicker required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="m-2">
        <mat-label>Analysis type</mat-label>
        <input matInput [(ngModel)]="analysisType" required>
      </mat-form-field>
    </mat-tab>
    <mat-tab label="Template">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Existing template</mat-label>
          <mat-select [(value)]="selectedTemplate">
            <mat-option>+ New template</mat-option>
            <mat-option [value]="option" *ngFor="let option of templates">{{option.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-stroked-button (click)="applyTemplate()" style="float:right">Apply</button>
        <button mat-stroked-button (click)="saveTemplate()" [disabled]="!showEditLayers" style="float:right">Save as new template</button>
        <button mat-stroked-button (click)="overrideTemplate()" [disabled]="!showEditLayers || selectedTemplate == null" style="float:right">Save current template</button>
      </div>
      <div class="form-group" *ngIf="showSelectColumns">
        <button mat-stroked-button (click)="addRow()" style="float:right">Add Row</button>
      </div>
      <div class="form-group" *ngIf="showEditLayers">
        <div cdkDropList class="template-list" (cdkDropListDropped)="drop($event)">
          <div cdkDrag class="template-box" *ngFor="let item of items; let first = first; let last = last; let index = index;">
            <div class="template-placeholder" *cdkDragPlaceholder></div>
            <div cdkDragHandle class="template-handle">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="6px" viewBox="0 0 16 6" version="1.1">
                <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Rounded" transform="translate(-308.000000, -2023.000000)">
                    <g id="Editor" transform="translate(100.000000, 1960.000000)">
                      <g id="-Round-/-Editor-/-drag_handle" transform="translate(204.000000, 54.000000)">
                        <g>
                          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                          <path d="M19,9 L5,9 C4.45,9 4,9.45 4,10 C4,10.55 4.45,11 5,11 L19,11 C19.55,11 20,10.55 20,10 C20,9.45 19.55,9 19,9 Z M5,15 L19,15 C19.55,15 20,14.55 20,14 C20,13.45 19.55,13 19,13 L5,13 C4.45,13 4,13.45 4,14 C4,14.55 4.45,15 5,15 Z" id="🔹-Icon-Color" fill="#888" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="template-group">
              <mat-form-field appearance="outline">
                <mat-label>Value</mat-label>
                <input matInput [(ngModel)]="item.pxlval" required>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Category name</mat-label>
                <input matInput [(ngModel)]="item.name" required>
              </mat-form-field>
              <mat-form-field appearance="outline" class="colorpicker">
                <mat-label>Fill color</mat-label>
                <input matInput [(ngModel)]="item.color" [mtxColorpicker]="fillpicker" required>
                <mtx-colorpicker-toggle matSuffix [for]="fillpicker"></mtx-colorpicker-toggle>
                <mtx-colorpicker #fillpicker></mtx-colorpicker>
                <mat-hint class="transparent-bg"><div [style.background-color]="item.color">&nbsp;</div></mat-hint>
              </mat-form-field>
              <!--<mat-form-field appearance="outline" class="colorpicker">
                <mat-label>Outline color</mat-label>
                <input matInput [(ngModel)]="item.outlineColor" [mtxColorpicker]="outlinepicker" required>
                <mtx-colorpicker-toggle matSuffix [for]="outlinepicker"></mtx-colorpicker-toggle>
                <mtx-colorpicker #outlinepicker></mtx-colorpicker>
                <mat-hint class="transparent-bg"><div [style.background-color]="item.outlineColor">&nbsp;</div></mat-hint>
              </mat-form-field>-->
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-stroked-button [disabled]="!showEditLayers" (click)="import()" cdkFocusInitial>Import</button>
</mat-dialog-actions>
<div class="loader-container" *ngIf="loading">
  <div class="loader-contents">
    <mat-spinner></mat-spinner>
  </div>
</div>
